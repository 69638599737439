import { Loader } from "_components";
import { Fragment } from "react";
import ReactApexChart from "react-apexcharts";
import { getChartColorsArray } from "_utils";

export const PieChartAllCurrencySales = ({
  loadingAllCurrencyData,
  allCurrencyData,
}) => {
  const cloneCurrencyData = [...allCurrencyData];
  cloneCurrencyData.sort(
    (a, b) => parseFloat(b.totalProfit) - parseFloat(a.totalProfit)
  );

  return (
    <Fragment>
      <div className="card card-height-100">
        {loadingAllCurrencyData ? (
          <Loader />
        ) : (
          <Fragment>
            <div className="card-header border-0 align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Sales</h4>
              <div></div>
            </div>
            <div className="card-body">
              <div
                id="portfolio_donut_charts"
                className="apex-charts"
                dir="ltr"
              >
                <SalesCharts
                  chartData={allCurrencyData}
                  dataColors='["--vz-primary", "--vz-secondary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]'
                />
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

const SalesCharts = ({ dataColors, chartData }) => {
  const chartDonutBasicColors = getChartColorsArray(dataColors);
  const series = chartData.map((x) => +x.totalProfit.toFixed(2));
  const labels = chartData.map((x) => x.currency.toUpperCase());
  var options = {
    labels,
    chart: {
      height: 333,
      type: "donut",
    },
    legend: {
      position: "bottom",
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    colors: chartDonutBasicColors,
  };

  return (
    <Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height="333"
        className="apex-charts"
      />
    </Fragment>
  );
};
