import { Loader } from "_components";
import { Fragment } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import CountUp from "react-countup";
import { useQuery } from "@apollo/client";
import { TOTAL_VISIT_LOGS } from "_queries/Admin";
import { queryFilters } from "_utils";

export const SalesBlocks = ({
  startDate,
  endDate,
  loadingAllCurrencyData,
  selectedCurrencyData,
  selectedCurrency,
}) => {
  const { data: dataTotalVisitLogs, loading: loadingTotalVisitLogs } = useQuery(
    TOTAL_VISIT_LOGS,
    {
      variables: queryFilters({
        transactionDate: { start: startDate, end: endDate },
      }),
      fetchPolicy: "no-cache",
    }
  );
  const totalVisitLogs = dataTotalVisitLogs?.countLogsAdmin.count;

  return (
    <Fragment>
      <Row>
        <Card className="crm-widget">
          <CardBody className="p-0">
            <Row className="row-cols-md-3 row-cols-1">
              <Col className={"col-lg border-end"}>
                <div className="mt-3 mt-md-0 py-4 px-3">
                  {loadingAllCurrencyData ? (
                    <Loader />
                  ) : (
                    <Fragment>
                      <h5 className="text-muted text-uppercase fs-13">
                        Sales
                        <i className={"fs-18 float-end align-middle "}></i>
                      </h5>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <i
                            className={"display-6 text-muted ri-numbers-fill"}
                          ></i>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h2 className="mb-0">
                            <span className="counter-value">
                              <CountUp
                                start={0}
                                end={selectedCurrencyData?.totalSaleAmount}
                                separator={`,`}
                                duration={3}
                                decimals={2}
                                prefix={`${selectedCurrency.toUpperCase()} `}
                              />
                            </span>
                          </h2>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </Col>
              <Col className={"col-lg border-end"}>
                <div className="mt-3 mt-md-0 py-4 px-3">
                  {loadingAllCurrencyData ? (
                    <Loader />
                  ) : (
                    <Fragment>
                      <h5 className="text-muted text-uppercase fs-13">
                        Orders
                        <i className={"fs-18 float-end align-middle "}></i>
                      </h5>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <i
                            className={"display-6 text-muted ri-numbers-fill"}
                          ></i>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h2 className="mb-0">
                            <span className="counter-value">
                              <CountUp
                                start={0}
                                end={selectedCurrencyData?.totalSales}
                                duration={3}
                              />
                            </span>
                          </h2>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </Col>
              <Col className={"col-lg border-end bg-secondary text-white"}>
                <div className="mt-3 mt-md-0 py-4 px-3">
                  {loadingTotalVisitLogs ? (
                    <Loader />
                  ) : (
                    <Fragment>
                      <h5 className="text-uppercase fs-13 text-white">
                        Clicks
                        <i className={"fs-18 float-end align-middle "}></i>
                      </h5>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <i
                            className={
                              "display-6 text-white ri-bar-chart-grouped-line"
                            }
                          ></i>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h2 className="mb-0">
                            <span className="counter-value text-white">
                              <CountUp
                                start={0}
                                end={totalVisitLogs}
                                separator={`,`}
                                duration={3}
                              />
                            </span>
                          </h2>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
      <Row>
        <Card className="crm-widget">
          <CardBody className="p-0">
            <Row className="row-cols-md-3 row-cols-1">
              <Col className={"col-lg border-end"}>
                <div className="mt-3 mt-md-0 py-4 px-3">
                  {loadingAllCurrencyData ? (
                    <Loader />
                  ) : (
                    <Fragment>
                      <h5 className="text-muted text-uppercase fs-13">
                        Commission
                        <i className={"fs-18 float-end align-middle "}></i>
                      </h5>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <i
                            className={"display-6 text-muted ri-funds-line"}
                          ></i>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h2 className="mb-0">
                            <span className="counter-value">
                              <CountUp
                                start={0}
                                end={
                                  selectedCurrencyData?.totalCost +
                                  selectedCurrencyData?.totalProfit
                                }
                                decimals={2}
                                separator={`,`}
                                prefix={`${selectedCurrency.toUpperCase()} `}
                                duration={3}
                              />
                            </span>
                          </h2>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </Col>
              <Col className={"col-lg border-end"}>
                <div className="mt-3 mt-md-0 py-4 px-3">
                  {loadingAllCurrencyData ? (
                    <Loader />
                  ) : (
                    <Fragment>
                      <h5 className="text-muted text-uppercase fs-13">
                        Payable
                        <i className={"fs-18 float-end align-middle "}></i>
                      </h5>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <i
                            className={"display-6 text-muted ri-currency-line"}
                          ></i>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h2 className="mb-0">
                            <span className="counter-value">
                              <CountUp
                                start={0}
                                end={selectedCurrencyData?.totalCost}
                                decimals={2}
                                separator={`,`}
                                prefix={`${selectedCurrency.toUpperCase()} `}
                                duration={3}
                              />
                            </span>
                          </h2>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </Col>
              <Col className={"col-lg border-end"}>
                <div className="mt-3 mt-md-0 py-4 px-3">
                  {loadingAllCurrencyData ? (
                    <Loader />
                  ) : (
                    <Fragment>
                      <h5 className="text-muted text-uppercase fs-13">
                        Profit
                        <i className={"fs-18 float-end align-middle "}></i>
                      </h5>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <i
                            className={"display-6 text-muted ri-award-line"}
                          ></i>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h2 className="mb-0">
                            <span className="counter-value">
                              <CountUp
                                start={0}
                                end={selectedCurrencyData?.totalProfit}
                                decimals={2}
                                separator={`,`}
                                prefix={`${selectedCurrency.toUpperCase()} `}
                                duration={3}
                              />
                            </span>
                          </h2>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
    </Fragment>
  );
};
