import { TransactionStatusDropdown } from "_components";
import { Fragment, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useMutation } from "@apollo/client";
import { UPDATE_TRANSACTIONS_STATUS } from "_queries/Admin";
import { toast } from "react-toastify";
import { TransactionStatus } from "_constants";
import { firstLetterCapital } from "_utils";

const transactionStatuses = [
  {
    label: firstLetterCapital(TransactionStatus.AWAITING_PAYMENT),
    value: TransactionStatus.AWAITING_PAYMENT,
  },
];

export const Actions = ({
  selectedTransactions,
  setSelectedTransactions,
  refetch,
}) => {
  const [transactionsAction, setTransactionsAction] = useState(null);
  const [
    updateTransactionsStatusFun,
    { loading: updateTransactionStatusLoading },
  ] = useMutation(UPDATE_TRANSACTIONS_STATUS);

  const handleActionApply = async () => {
    if (selectedTransactions.length === 0) {
      toast.error(`Please select the transactions!`);
      return false;
    }

    if (!transactionsAction) {
      toast.error(`Please select the action!`);
      return false;
    }

    const response = await updateTransactionsStatusFun({
      variables: { transactionIds: selectedTransactions },
    });

    if (response) {
      toast.success(`Transaction(s) status have been changed.`);
      refetch();
      setSelectedTransactions([]);
    }
  };

  return (
    <Fragment>
      <Card>
        <CardHeader className="card-header border-0">
          <div className="d-flex align-items-center">
            <h5 className="card-title mb-0 flex-grow-1">Actions</h5>
            <div className="flex-shrink-0"></div>
          </div>
        </CardHeader>
        <CardBody className="border border-dashed border-end-0 border-start-0">
          <div className="row g-3">
            <div className="col-xxl-3 col-sm-4">
              <TransactionStatusDropdown
                statuses={transactionStatuses}
                onTransactionStatusChange={(status) => {
                  setTransactionsAction(status);
                }}
              />
            </div>

            <div className="col-xxl-2 col-sm-4">
              <button
                type="button"
                className="btn btn-secondary w-50"
                disabled={updateTransactionStatusLoading}
                onClick={handleActionApply}
              >
                <i className="ri-save-fill me-1 align-bottom"></i>
                Apply
              </button>
            </div>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};
