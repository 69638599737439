import { InMemoryCache } from "@apollo/client";
import { varUserInfo } from "./userInfo";
import { varAuthToken } from "./authToken";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        authToken: {
          read() {
            return varAuthToken();
          },
        },
        userInfo: {
          read() {
            return varUserInfo();
          },
        },
      },
    },
  },
});

export default cache;
