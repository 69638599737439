import Select from "react-select";

export const TransactionStatusDropdown = (props) => {
  const { onTransactionStatusChange } = props;

  const handleChange = (inputValue) => {
    const val = inputValue ? inputValue.value : "all";
    onTransactionStatusChange(val);
  };

  return (
    <Select
      placeholder={`Select Status`}
      isClearable={true}
      defaultOptions={false}
      options={props.statuses}
      onChange={handleChange}
      menuPortalTarget={document.body}
    />
  );
};
