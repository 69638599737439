import { Fragment } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ALL_OFFER_APPROVAL_TYPES,
  GET_ALL_OFFER_PERFORMANCE_TYPES,
  GET_ALL_PLATFORMS,
  GET_OFFER,
  UPDATE_OFFER,
} from "_queries/Admin";
import countries from "_data/countries";
import currencies from "_data/currencies";
import { firstLetterCapital, transformGQLErrors } from "_utils";
import { GET_ALL_CATEGORIES } from "_queries/Shared";
import { Loader } from "_components";
import { toast } from "react-toastify";

export const EditOffer = () => {
  const { id } = useParams();

  const { data: dataApprovalTypes } = useQuery(GET_ALL_OFFER_APPROVAL_TYPES);
  const { data: dataPerformanceTypes } = useQuery(
    GET_ALL_OFFER_PERFORMANCE_TYPES
  );
  const { data: dataPlatforms } = useQuery(GET_ALL_PLATFORMS);
  const { data: dataCategories } = useQuery(GET_ALL_CATEGORIES);
  const { data, loading } = useQuery(GET_OFFER, {
    variables: { id },
    fetchPolicy: "no-cache",
  });
  const [updateOffer, { loading: loadingUpdateOffer }] = useMutation(
    UPDATE_OFFER,
    {
      errorPolicy: "all",
    }
  );

  const offer = data?.offer;
  const categories = dataCategories?.categories;
  const approvalTypes = dataApprovalTypes?.offerApprovalTypes;
  const performanceTypes = dataPerformanceTypes?.offerPerformanceTypes;
  const platforms = dataPlatforms?.allPlatforms.platforms.map((platform) => {
    return {
      _id: platform,
      title: `${firstLetterCapital(platform)}`,
    };
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      offerName: offer?.offerName,
      commission: offer?.commission,
      website: offer?.website,
      country: offer?.country ? firstLetterCapital(offer?.country) : "",
      currency: offer?.currency ? offer?.currency : "",
      category: offer?.category?._id,
      platform: offer?.platform,
      platformOfferId: offer?.platformOfferId,
      platformTrackingUrl: offer?.platformTrackingUrl,
      approval: offer?.approval,
      performance: offer?.performance,
      featured: offer?.featured,
      logo: offer?.logo,
    },
    validationSchema: Yup.object({
      offerName: Yup.string().required("Please Enter a Offer Name"),
      commission: Yup.number().required("Please Enter Commission"),
      website: Yup.string().required("Please Enter a Website"),
      country: Yup.string().required("Please Select a Country"),
      currency: Yup.string().required("Please Select a Currency"),
      category: Yup.string().required("Please Select a Category"),
      platform: Yup.string().required("Please Select a Platform"),
      platformOfferId: Yup.string().required(
        "Please Enter a platform Offer ID"
      ),
      platformTrackingUrl: Yup.string().required(
        "Please Enter a platform Tracking URL"
      ),
      approval: Yup.string().required("Please Select a Offer Approval"),
      performance: Yup.string().required("Please Select a Offer Performance"),
      featured: Yup.boolean(),
      logo: Yup.string().required("Please Enter a Logo Name"),
    }),
    onSubmit: async (values) => {
      const updatedData = {
        offerName: values.offerName,
        commission: parseFloat(values.commission),
        website: values.website,
        country: values.country,
        currency: values.currency,
        category: values.category,
        platform: values.platform,
        platformOfferId: values.platformOfferId,
        platformTrackingUrl: values.platformTrackingUrl,
        approval: values.approval,
        performance: values.performance,
        featured: values.featured,
        termsNConditions: null,
        logo: values.logo,
        id: offer?._id,
      };

      const response = await updateOffer({
        variables: updatedData,
      });
      if (response) {
        const offerId =
          response &&
          response.data &&
          response.data.updateOffer &&
          response.data.updateOffer._id;
        if (offerId) {
          toast.success("Offer has been updated.");
        }
      }
      //Show errors if any
      transformGQLErrors(response).map((e) => toast.error(e));
    },
  });
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col lg={8}>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">
                      Offer - {offer.referenceId}
                    </h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label">Offer Name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="offerName"
                            value={validation.values.offerName || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.offerName &&
                              validation.touched.offerName
                                ? true
                                : false
                            }
                          />
                          {validation.errors.offerName &&
                          validation.touched.offerName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.offerName}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label">Commission</Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="commission"
                            value={validation.values.commission || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.commission &&
                              validation.touched.commission
                                ? true
                                : false
                            }
                          />
                          {validation.errors.commission &&
                          validation.touched.commission ? (
                            <FormFeedback type="invalid">
                              {validation.errors.commission}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label">Website</Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="website"
                            value={validation.values.website || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.website &&
                              validation.touched.website
                                ? true
                                : false
                            }
                          />
                          {validation.errors.website &&
                          validation.touched.website ? (
                            <FormFeedback type="invalid">
                              {validation.errors.website}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label">Country</Label>
                          <Input
                            name="country"
                            type="select"
                            className="form-select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.country || ""}
                          >
                            {(countries || []).map((item, key) => (
                              <Fragment key={key}>
                                <option value={item.name} key={key}>
                                  {item.name}
                                </option>
                              </Fragment>
                            ))}
                          </Input>
                          {validation.touched.country &&
                          validation.errors.country ? (
                            <FormFeedback type="invalid">
                              {validation.errors.country}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label">Currency</Label>
                          <Input
                            name="currency"
                            type="select"
                            className="form-select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.currency || ""}
                          >
                            {(currencies || []).map((item, key) => (
                              <Fragment key={key}>
                                <option value={item.cc.toLowerCase()} key={key}>
                                  {item.cc}
                                </option>
                              </Fragment>
                            ))}
                          </Input>
                          {validation.touched.currency &&
                          validation.errors.currency ? (
                            <FormFeedback type="invalid">
                              {validation.errors.currency}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label">Category</Label>
                          <Input
                            name="category"
                            type="select"
                            className="form-select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.category || ""}
                          >
                            {(categories || []).map((item, key) => (
                              <Fragment key={key}>
                                <option value={item._id} key={key}>
                                  {item.categoryName}
                                </option>
                              </Fragment>
                            ))}
                          </Input>
                          {validation.touched.category &&
                          validation.errors.category ? (
                            <FormFeedback type="invalid">
                              {validation.errors.category}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label">Logo Name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="logo"
                            value={validation.values.logo || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.logo && validation.touched.logo
                                ? true
                                : false
                            }
                          />
                          {validation.errors.logo && validation.touched.logo ? (
                            <FormFeedback type="invalid">
                              {validation.errors.logo}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Platform Info</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label">Platform</Label>
                          <Input
                            name="platform"
                            type="select"
                            className="form-select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.platform || ""}
                          >
                            {(platforms || []).map((item, key) => (
                              <Fragment key={key}>
                                <option value={item._id} key={key}>
                                  {item.title}
                                </option>
                              </Fragment>
                            ))}
                          </Input>
                          {validation.touched.platform &&
                          validation.errors.platform ? (
                            <FormFeedback type="invalid">
                              {validation.errors.platform}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Platform Offer ID
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="platformOfferId"
                            value={validation.values.platformOfferId || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.platformOfferId &&
                              validation.touched.platformOfferId
                                ? true
                                : false
                            }
                          />
                          {validation.errors.platformOfferId &&
                          validation.touched.platformOfferId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.platformOfferId}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Platform Tracking URL
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="platformTrackingUrl"
                            value={validation.values.platformTrackingUrl || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.platformTrackingUrl &&
                              validation.touched.platformTrackingUrl
                                ? true
                                : false
                            }
                          />
                          {validation.errors.platformTrackingUrl &&
                          validation.touched.platformTrackingUrl ? (
                            <FormFeedback type="invalid">
                              {validation.errors.platformTrackingUrl}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Status</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="form-label">Approval</Label>
                      <Input
                        name="approval"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.approval || ""}
                      >
                        {(approvalTypes || []).map((approvalType, key) => (
                          <Fragment key={key}>
                            <option value={approvalType} key={key}>
                              {firstLetterCapital(approvalType)}
                            </option>
                          </Fragment>
                        ))}
                      </Input>
                      {validation.touched.approval &&
                      validation.errors.approval ? (
                        <FormFeedback type="invalid">
                          {validation.errors.approval}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Performance</Label>
                      <Input
                        name="performance"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.performance || ""}
                      >
                        {(performanceTypes || []).map(
                          (performanceType, key) => (
                            <Fragment key={key}>
                              <option value={performanceType} key={key}>
                                {firstLetterCapital(performanceType)}
                              </option>
                            </Fragment>
                          )
                        )}
                      </Input>
                      {validation.touched.performance &&
                      validation.errors.performance ? (
                        <FormFeedback type="invalid">
                          {validation.errors.performance}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <div className="form-check form-switch form-check-right">
                        <Input
                          name="featured"
                          type="checkbox"
                          className="form-check-input"
                          role="switch"
                          defaultChecked={offer.featured}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                        <Label className="form-check-label">Featured</Label>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <div className="text-end mb-3">
                <button
                  type="submit"
                  className="btn btn-success w-sm"
                  disabled={loadingUpdateOffer}
                >
                  Submit
                </button>
              </div>
            </Row>
          </Form>
        </Fragment>
      )}
    </Fragment>
  );
};
