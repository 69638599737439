import { gql } from "@apollo/client";

export const GET_ALL_INVOICES = gql`
  query (
    $filters: FiltersExpression!
    $pagination: PaginationInput!
    $orderBy: OrderByInput!
  ) {
    invoicesAdmin(
      filters: $filters
      pagination: $pagination
      orderBy: $orderBy
    ) {
      count
      invoices {
        _id
        invoiceNo
        fromCurrency
        fromAmount
        paidCurrency
        paidAmount
        status
        requestedDate
        paidDate
        user {
          firstName
          lastName
          website
          referenceId
        }
      }
    }
  }
`;

export const PAY_INVOICE = gql`
  mutation PayInvoice($invoiceId: String!) {
    payInvoice(payInvoiceInput: { invoiceId: $invoiceId }) {
      _id
    }
  }
`;

export const DELETE_INVOICE = gql`
  mutation DeleteInvoice($invoiceId: String!) {
    deleteInvoice(deleteInvoiceInput: { invoiceId: $invoiceId }) {
      _id
    }
  }
`;

export const GENERATED_INVOICE_STATS = gql`
  query {
    generatedInvoiceStatsAdmin {
      currency
      invoiceAmount
    }
  }
`;
