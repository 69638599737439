import { gql } from "@apollo/client";

export const REGISTER_MUTATION = gql`
  mutation LogIn(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $password: String!
    $country: String!
    $state: String!
    $city: String!
    $zipcode: String!
    $address: String!
    $website: String!
    $websiteType: String!
    $skypeId: String!
  ) {
    createUser(
      createUserInput: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        password: $password
        country: $country
        state: $state
        city: $city
        zipcode: $zipcode
        address: $address
        website: $website
        websiteType: $websiteType
        skypeId: $skypeId
      }
    ) {
      _id
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation LogIn($email: String!, $password: String!) {
    logIn(authenticateUserInput: { email: $email, password: $password }) {
      access_token
    }
  }
`;

export const LOGIN_BY_ID_MUTATION = gql`
  mutation LogInById($id: String!) {
    logInById(authenticateUserByIdInput: { id: $id }) {
      access_token
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(forgotPasswordInput: { email: $email })
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($password: String!, $token: String!, $ref: String!) {
    resetPassword(
      resetPasswordInput: { password: $password, token: $token, ref: $ref }
    )
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePassword(
    $id: String!
    $password: String!
    $newPassword: String!
  ) {
    changePassword(
      changePasswordInput: {
        id: $id
        password: $password
        newPassword: $newPassword
      }
    )
  }
`;

export const UPDATE_USER_STATUS_BY_ID_MUTATION = gql`
  mutation updateStatus($id: String!, $status: String!) {
    updateStatus(updateUserStatusInput: { id: $id, status: $status }) {
      _id
    }
  }
`;

export const SEND_EMAIL_CONFIRMATION_BY_ID_MUTATION = gql`
  mutation sendConfirmationEmail($id: String!) {
    sendConfirmationEmail(sendConfirmationEmailInput: { id: $id })
  }
`;

export const GET_PROFILE = gql`
  query {
    me {
      _id
      firstName
      lastName
      email
      phone
      country
      city
      zipcode
      address
      state
      website
      websiteType
      skypeId
      referenceId
      roles
      bankAccountDetails {
        _id
        paymentMethod
        email
        bankName
        bankAddress
        accountType
        accountName
        accountNumber
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateUser(
    $id: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $country: String!
    $state: String!
    $city: String!
    $zipcode: String!
    $address: String!
    $websiteType: String!
    $skypeId: String!
  ) {
    updateUser(
      updateUserInput: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        country: $country
        state: $state
        city: $city
        zipcode: $zipcode
        address: $address
        websiteType: $websiteType
        skypeId: $skypeId
      }
    ) {
      _id
    }
  }
`;

export const CREATE_PAYMENT_MODE_MUTATION = gql`
  mutation createBankAccount(
    $paymentMethod: String!
    $email: String
    $bankName: String
    $bankAddress: String
    $accountType: String
    $accountName: String
    $accountNumber: String
  ) {
    createBankAccount(
      createBankAccountInput: {
        paymentMethod: $paymentMethod
        email: $email
        bankName: $bankName
        bankAddress: $bankAddress
        accountType: $accountType
        accountName: $accountName
        accountNumber: $accountNumber
      }
    ) {
      _id
    }
  }
`;
