import { useMutation, useQuery } from "@apollo/client";
import { Fragment, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { Loader } from "_components";
import {
  CHANGE_PASSWORD_MUTATION,
  GET_PROFILE,
  UPDATE_PROFILE,
} from "_queries/Shared";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import countries from "_data/countries";
import { toast } from "react-toastify";
import { transformGQLErrors } from "_utils";
import { useUserInfo } from "_hooks/Shared";

const websiteTypes = [
  "Blog",
  "Network",
  "Community",
  "Forum",
  "Cashback or Loyalty",
  "Parked Domain",
  "Coupon or Rebate",
  "Search Engine",
  "SEM",
  "Other",
];

const schema = yup
  .object({
    firstName: yup.string().required("First name is required."),
    lastName: yup.string().required("Last name is required."),
    email: yup.string().email().required("Email is required."),
    phone: yup.string().required("Phone is required."),
    address: yup.string().required("Address is required."),
    state: yup.string().required("State is required."),
    city: yup.string().required("City is required."),
    zipcode: yup.string().required("Zip code is required."),
    skypeId: yup.string().required("Skype ID is required."),
  })
  .required();

const schemaChangePassword = yup
  .object({
    password: yup.string().required("Password is required."),
    newPassword: yup.string().required("New password is required."),
    confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  })
  .required();

export const Profile = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [updateUser, { loading: loadingUpdateUser }] = useMutation(
    UPDATE_PROFILE,
    {
      errorPolicy: "all",
    }
  );
  const [changePassword, { loading: loadingChangePassword }] = useMutation(
    CHANGE_PASSWORD_MUTATION,
    {
      errorPolicy: "all",
    }
  );
  const {
    userInfo: { _id: userId },
  } = useUserInfo();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    register: registerChangePassword,
    handleSubmit: handleSubmitChangePassword,
    formState: { errors: errorsChangePassword },
    reset,
  } = useForm({
    resolver: yupResolver(schemaChangePassword),
  });

  const { data, loading, refetch } = useQuery(GET_PROFILE, {
    variables: {},
    fetchPolicy: "no-cache",
  });
  const profile = data?.me;

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const onSubmit = async (data) => {
    const updatedData = { ...data, id: userId };
    const response = await updateUser({
      variables: updatedData,
    });
    if (response) {
      const userId =
        response &&
        response.data &&
        response.data.updateUser &&
        response.data.updateUser._id;
      if (userId) {
        refetch();
        toast.success("Profile has been updated.");
      }
    }
    //Show errors if any
    transformGQLErrors(response).map((e) => toast.error(e));
  };

  const onSubmitChangePassword = async (data) => {
    const updatedData = { ...data, id: userId };
    const response = await changePassword({
      variables: updatedData,
    });
    if (response) {
      const userId = response && response.data && response.data.changePassword;
      if (userId) {
        reset();
        toast.success("Password has been changed.");
      }
    }
    //Show errors if any
    transformGQLErrors(response).map((e) => toast.error(e));
  };

  return (
    <Fragment>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader>
              <Nav
                className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: activeTab === "1" },
                      "text-body"
                    )}
                    onClick={() => {
                      tabChange("1");
                    }}
                  >
                    <i className="fas fa-home"></i>
                    Personal Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames(
                      { active: activeTab === "2" },
                      "text-body"
                    )}
                    onClick={() => {
                      tabChange("2");
                    }}
                    type="button"
                  >
                    <i className="far fa-user"></i>
                    Change Password
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody className="p-4">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  {loading ? (
                    <Loader />
                  ) : (
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label className="form-label">First Name</Label>
                            <input
                              type="text"
                              placeholder="First Name"
                              {...register("firstName")}
                              className={`form-control  ${
                                errors.firstName ? "is-invalid" : ""
                              }`}
                              defaultValue={profile.firstName}
                            />
                            <div className="invalid-feedback">
                              {errors.firstName?.message}
                            </div>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label className="form-label">Last Name</Label>
                            <input
                              type="text"
                              placeholder="Last Name"
                              {...register("lastName")}
                              className={`form-control  ${
                                errors.lastName ? "is-invalid" : ""
                              }`}
                              defaultValue={profile.lastName}
                            />
                            <div className="invalid-feedback">
                              {errors.lastName?.message}
                            </div>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <input
                              type="text"
                              placeholder="Email"
                              {...register("email")}
                              className={`form-control  ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              defaultValue={profile.email}
                            />
                            <div className="invalid-feedback">
                              {errors.email?.message}
                            </div>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label className="form-label">Phone</Label>
                            <input
                              type="text"
                              placeholder="Phone"
                              {...register("phone")}
                              className={`form-control  ${
                                errors.phone ? "is-invalid" : ""
                              }`}
                              defaultValue={profile.phone}
                            />
                            <div className="invalid-feedback">
                              {errors.phone?.message}
                            </div>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label className="form-label">Country</Label>
                            <select
                              {...register("country")}
                              className={`form-control form-select ${
                                errors.country ? "is-invalid" : ""
                              }`}
                              defaultValue={profile.country}
                            >
                              {(countries || []).map((country, index) => {
                                return (
                                  <option value={country.name} key={index}>
                                    {country.name}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="invalid-feedback">
                              {errors.phone?.message}
                            </div>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label className="form-label">State</Label>
                            <input
                              type="text"
                              placeholder="State"
                              {...register("state")}
                              className={`form-control  ${
                                errors.state ? "is-invalid" : ""
                              }`}
                              defaultValue={profile.state}
                            />
                            <div className="invalid-feedback">
                              {errors.state?.message}
                            </div>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label className="form-label">City</Label>
                            <input
                              type="text"
                              placeholder="City"
                              {...register("city")}
                              className={`form-control  ${
                                errors.city ? "is-invalid" : ""
                              }`}
                              defaultValue={profile.city}
                            />
                            <div className="invalid-feedback">
                              {errors.city?.message}
                            </div>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label className="form-label">Zip code</Label>
                            <input
                              type="text"
                              placeholder="Zip code"
                              {...register("zipcode")}
                              className={`form-control  ${
                                errors.zipcode ? "is-invalid" : ""
                              }`}
                              defaultValue={profile.zipcode}
                            />
                            <div className="invalid-feedback">
                              {errors.zipcode?.message}
                            </div>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label className="form-label">Address</Label>
                            <input
                              type="text"
                              placeholder="Address"
                              {...register("address")}
                              className={`form-control  ${
                                errors.address ? "is-invalid" : ""
                              }`}
                              defaultValue={profile.address}
                            />
                            <div className="invalid-feedback">
                              {errors.address?.message}
                            </div>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label className="form-label">Website</Label>
                            <input
                              type="text"
                              placeholder="Website"
                              className={`form-control  ${
                                errors.website ? "is-invalid" : ""
                              }`}
                              defaultValue={profile.website}
                              disabled={true}
                            />
                            <small>https://www.google.com</small>
                            <div className="invalid-feedback">
                              {errors.website?.message}
                            </div>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label className="form-label">Website Type</Label>
                            <select
                              {...register("websiteType")}
                              className={`form-control form-select ${
                                errors.websiteType ? "is-invalid" : ""
                              }`}
                              defaultValue={profile.websiteType}
                            >
                              {(websiteTypes || []).map(
                                (websiteType, index) => {
                                  return (
                                    <option value={websiteType} key={index}>
                                      {websiteType}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                            <div className="invalid-feedback">
                              {errors.website?.message}
                            </div>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label className="form-label">Skype ID</Label>
                            <input
                              type="text"
                              placeholder="Skype ID"
                              {...register("skypeId")}
                              className={`form-control  ${
                                errors.skypeId ? "is-invalid" : ""
                              }`}
                              defaultValue={profile.skypeId}
                            />
                            <div className="invalid-feedback">
                              {errors.skypeId?.message}
                            </div>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="hstack gap-2 justify-content-end">
                            <Button
                              className="btn btn-secondary"
                              disabled={loadingUpdateUser}
                            >
                              Save
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </TabPane>
                <TabPane tabId="2">
                  {loading ? (
                    <Loader />
                  ) : (
                    <Form
                      onSubmit={handleSubmitChangePassword(
                        onSubmitChangePassword
                      )}
                    >
                      <Row className="g-2">
                        <Col lg={4}>
                          <div>
                            <Label className="form-label">Old Password</Label>
                            <input
                              type="password"
                              placeholder="Old Password"
                              {...registerChangePassword("password")}
                              className={`form-control  ${
                                errorsChangePassword.password
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <div className="invalid-feedback">
                              {errorsChangePassword.password?.message}
                            </div>
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div>
                            <Label className="form-label">New Password</Label>
                            <input
                              type="password"
                              placeholder="New Password"
                              {...registerChangePassword("newPassword")}
                              className={`form-control  ${
                                errorsChangePassword.newPassword
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <div className="invalid-feedback">
                              {errorsChangePassword.newPassword?.message}
                            </div>
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div>
                            <Label className="form-label">
                              Confirm Password
                            </Label>
                            <input
                              type="password"
                              placeholder="Confirm Password"
                              {...registerChangePassword("confirmPassword")}
                              className={`form-control  ${
                                errorsChangePassword.confirmPassword
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <div className="invalid-feedback">
                              {errorsChangePassword.confirmPassword?.message}
                            </div>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="text-end">
                            <Button
                              className="btn btn-secondary"
                              disabled={loadingChangePassword}
                            >
                              Change Password
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
