import { Fragment } from "react";

export const ListingCheckboxAll = ({ onCheckboxAllChange }) => {
  const handleCheckboxAllChange = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".taskCheckBox");
    let newSelectedTransactions = [];

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
        newSelectedTransactions.push({
          isChecked: true,
          isCheckedValue: ele.value,
        });
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
        newSelectedTransactions.push({
          isChecked: false,
          isCheckedValue: ele.value,
        });
      });
    }

    onCheckboxAllChange(newSelectedTransactions);
  };

  return (
    <Fragment>
      <input
        type="checkbox"
        id="checkBoxAll"
        className="form-check-input"
        onChange={handleCheckboxAllChange}
      />
    </Fragment>
  );
};
