import { Fragment, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { offersFilter } from "_utils";
import { DEFAULT_PER_PAGE } from "_constants";
import { Filters } from "./Filters";
import { Listing } from "./Listing";
import { useMutation, useQuery } from "@apollo/client";
import { APPLY_OFFERS, GET_ALL_OFFERS } from "_queries/Affiliate";
import { toast } from "react-toastify";

export const AllOffers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [filters, setFilters] = useState(null);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [applyOffers, { loading: loadingApplyOffers }] =
    useMutation(APPLY_OFFERS);

  const {
    data,
    loading: loadingOffers,
    refetch,
  } = useQuery(GET_ALL_OFFERS, {
    variables: offersFilter(currentPage, perPage, filters),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filters]);

  const paginatedData = data?.allOffers?.offers;
  const numberOfTotalPages = data?.allOffers.count;

  const handleFilterChange = (key, value) => {
    setFilters(() => {
      return {
        ...filters,
        [key]: value,
      };
    });
  };

  const handleApplyOffers = async () => {
    const response = await applyOffers({
      variables: { offerIds: selectedOffers },
    });
    if (response) {
      toast.success(`All selected advertisers have been applied.`);
      setSelectedOffers([]);
      refetch();
    }
  };

  const filtersProps = {
    setCurrentPage,
    handleFilterChange,
  };

  const listingProps = {
    numberOfTotalPages,
    paginatedData,
    setCurrentPage,
    setPerPage,
    currentPage,
    perPage,
    loadingOffers,
    refetch,
    selectedOffers,
    setSelectedOffers,
    handleApplyOffers,
    loadingApplyOffers,
  };

  return (
    <Fragment>
      <Row id="filter">
        <Col lg={12}>
          <Filters {...filtersProps} />
        </Col>
      </Row>
      <Row id="listing">
        <Col lg={12}>
          <Listing {...listingProps} />
        </Col>
      </Row>
    </Fragment>
  );
};
