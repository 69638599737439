import { useMutation, useQuery } from "@apollo/client";
import { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { GET_ALL_INVOICES, PAY_INVOICE } from "_queries/Admin";
import { invoicesFilter } from "_utils";
import moment from "moment/moment";
import {
  InvoiceListingTable,
  Loader,
  PerPageDropdown,
  Pagination,
  DownloadInvoiceButton,
  UserInfoInTable,
  DeletedInvoiceButton,
  ReferenceTransactionsLink,
} from "_components";
import {
  DEFAULT_PER_PAGE,
  DEFAULT_START_DATE,
  InvoiceStatus,
} from "_constants";
import { toast } from "react-toastify";

export const Invoices = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [filters] = useState({
    invoiceDate: {
      start: DEFAULT_START_DATE,
      end: moment().endOf("month").format("YYYY-MM-DD"),
    },
  });

  const columns = [
    { label: "Invoice No", class: "min-w-100px" },
    { label: "From Currency", class: "min-w-200px" },
    { label: "From Amount", class: "min-w-150px" },
    { label: "Paid Currency", class: "min-w-150px" },
    { label: "Paid Amount", class: "min-w-150px" },
    { label: "Requested Date", class: "min-w-50px" },
    { label: "Paid Date", class: "min-w-50px" },
    { label: "Status", class: "min-w-100px" },
    { label: "User", class: "min-w-100px" },
    { label: "Action", class: "min-w-100px" },
  ];

  const {
    data: dataInvoices,
    loading,
    refetch,
  } = useQuery(GET_ALL_INVOICES, {
    variables: invoicesFilter(currentPage, perPage, filters),
    fetchPolicy: "no-cache",
  });
  const invoicesData = dataInvoices?.invoicesAdmin?.invoices;
  const numberOfTotalPages = dataInvoices?.invoicesAdmin.count;

  useEffect(() => {
    refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filters]);

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader className="card-header border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">Invoices</h5>
                <div className="flex-shrink-0"></div>
              </div>
            </CardHeader>
            <CardBody>
              <div className="table-responsive table-card mb-4 table-transaction">
                {loading ? (
                  <Loader />
                ) : (
                  <InvoiceListingTable
                    columns={columns}
                    data={invoicesData}
                    refetch={refetch}
                    AdditionalColumnsValuesComponents={AdditionalColumnsValues}
                  ></InvoiceListingTable>
                )}
              </div>
              <div
                className="d-flex mt-2"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <PerPageDropdown
                    onPerPageChange={(perPage) => {
                      setPerPage(perPage);
                      setCurrentPage(1);
                    }}
                  />
                </div>
                <div className="pagination-wrap hstack gap-2">
                  {invoicesData?.length > 0 && (
                    <Pagination
                      className="pagination"
                      currentPage={currentPage}
                      totalCount={numberOfTotalPages}
                      pageSize={perPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export const AdditionalColumnsValues = (props) => {
  const [payBtnDisabled, setPayBtnDisabled] = useState(false);
  const [payInvoice] = useMutation(PAY_INVOICE, {
    errorPolicy: "all",
  });

  const handlePayBtn = async () => {
    setPayBtnDisabled(true);

    const response = await payInvoice({
      variables: { invoiceId: props.invoice._id },
    });

    if (response) {
      toast.success(`Invoice status has been changed to PAID.`);
      props.refetch();
    }
  };

  return (
    <Fragment>
      <td>
        <UserInfoInTable user={props.invoice.user} />
      </td>
      <td>
        <button
          className="btn btn-sm btn-secondary"
          onClick={handlePayBtn}
          disabled={
            payBtnDisabled || props.invoice.status === InvoiceStatus.PAID
          }
        >
          Pay
        </button>
        <DownloadInvoiceButton invoice={props.invoice} />
        <ReferenceTransactionsLink invoice={props.invoice} />
        <DeletedInvoiceButton invoice={props.invoice} refetch={props.refetch} />
      </td>
    </Fragment>
  );
};
