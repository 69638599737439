import { gql } from "@apollo/client";

export const GET_ALL_INVOICES = gql`
  query (
    $filters: FiltersExpression!
    $pagination: PaginationInput!
    $orderBy: OrderByInput!
  ) {
    invoices(filters: $filters, pagination: $pagination, orderBy: $orderBy) {
      count
      invoices {
        _id
        invoiceNo
        fromCurrency
        fromAmount
        paidCurrency
        paidAmount
        status
        requestedDate
        paidDate
      }
    }
  }
`;

export const CREATE_INVOICE = gql`
  mutation CreateInvoice(
    $fromCurrency: String!
    $toCurrency: String!
    $bankAccountId: String!
  ) {
    createInvoice(
      createInvoiceInput: {
        fromCurrency: $fromCurrency
        toCurrency: $toCurrency
        bankAccountId: $bankAccountId
      }
    ) {
      invoiceNo
    }
  }
`;

export const CURRENCY_EXCHANGES = gql`
  query ($currencies: [String!]!) {
    currecyExchange(currencyExchangesInput: { currencies: $currencies }) {
      base
      rates {
        currency
        rate
      }
    }
  }
`;
