import { useQuery } from "@apollo/client";
import { varAuthToken } from "_storage";
import { gql } from "@apollo/client";
import { KEY_AUTH_TOKEN } from "_constants";

const GET_AUTH_TOKEN = gql`
  query GetAuthToken {
    authToken @client
  }
`;

const saveToLocalStorage = () => {
  window.localStorage.setItem(KEY_AUTH_TOKEN, varAuthToken());
};

export const removeFromLocalStorage = () => {
  window.localStorage.removeItem(KEY_AUTH_TOKEN);
};

export const useAuthToken = () => {
  const {
    data: { authToken },
  } = useQuery(GET_AUTH_TOKEN);

  const setAuthToken = (authToken) => {
    varAuthToken(authToken);
    saveToLocalStorage();
  };

  const removeAuthToken = () => {
    removeFromLocalStorage();
  };

  return { authToken, setAuthToken, removeAuthToken };
};
