import { DEFAULT_PER_PAGE } from "_constants";
import { monthStartEndDates } from "./month-start-end-dates";

export const transactionsFilter = (currentPage, perPage, filters) => {
  let { startDate, endDate } = monthStartEndDates();
  const filtersObj = {
    fields: [],
  };

  if (
    filters &&
    filters.transactionDate &&
    filters.transactionDate.start &&
    filters.transactionDate.end
  ) {
    const { start, end } = filters.transactionDate;
    startDate = start;
    endDate = end;
  }
  filtersObj.fields.push({
    field: "startDate",
    value: startDate,
    type: "normal",
  });
  filtersObj.fields.push({
    field: "endDate",
    value: endDate,
    type: "normal",
  });

  if (filters && filters.offer && filters.offer !== "all") {
    filtersObj.fields.push({
      field: "offer._id",
      value: filters.offer,
      type: "mongo",
    });
  }

  if (filters && filters.user && filters.user !== "all") {
    filtersObj.fields.push({
      field: "user._id",
      value: filters.user,
      type: "mongo",
    });
  }

  if (filters && filters.currency && filters.currency !== "all") {
    filtersObj.fields.push({
      field: "currency",
      value: filters.currency.toLowerCase(),
      type: "normal",
    });
  }

  if (filters && filters.platform && filters.platform !== "all") {
    filtersObj.fields.push({
      field: "platform",
      value: filters.platform.toLowerCase(),
      type: "normal",
    });
  }

  if (filters && filters.status && filters.status !== "all") {
    filtersObj.fields.push({
      field: "status",
      value: JSON.stringify([filters.status.toLowerCase()]),
      type: "normal",
    });
  }

  if (filters && filters.period) {
    filtersObj.fields.push({
      field: "period",
      value: filters.period,
      type: "normal",
    });
  }

  if (filters && filters.invoice_no) {
    filtersObj.fields.push({
      field: "invoice_no",
      value: filters.invoice_no,
      type: "normal",
    });
  }

  const currentPagePagination = currentPage ? currentPage : 1;
  const perPagePagination = perPage ? perPage : DEFAULT_PER_PAGE;
  const skip = (currentPagePagination - 1) * perPagePagination;
  const take = perPagePagination;
  const orderBy = { field: "transactionDate", value: "desc" };
  return {
    filters: filtersObj,
    pagination: { take, skip },
    orderBy,
  };
};
