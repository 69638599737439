import { useMutation } from "@apollo/client";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Col, Form, Label, Row } from "reactstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CREATE_PAYMENT_MODE_MUTATION } from "_queries/Shared";
import { toast } from "react-toastify";
import { transformGQLErrors } from "_utils";

const schema = yup
  .object({
    paymentMethod: yup.string().required("Payment method is required."),
    email: yup
      .string()
      .email()
      .when("paymentMethod", {
        is: (paymentMethod) => {
          if (["paypal", "payoneer"].includes(paymentMethod)) {
            return true;
          }
          return false;
        },
        then: yup.string().required("Email address is required"),
      }),
    bankName: yup.string().when("paymentMethod", {
      is: "bank",
      then: yup.string().required("Bank name is required"),
    }),
    bankAddress: yup.string().when("paymentMethod", {
      is: "bank",
      then: yup.string().required("Bank address is required"),
    }),
    accountType: yup.string().when("paymentMethod", {
      is: "bank",
      then: yup.string().required("Account type is required"),
    }),
    accountName: yup.string().when("paymentMethod", {
      is: "bank",
      then: yup.string().required("Account name is required"),
    }),
    accountNumber: yup.string().when("paymentMethod", {
      is: "bank",
      then: yup.string().required("Account number is required"),
    }),
  })
  .required();

export const AddBankAccount = ({ refetchAccountDetails }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("paypal");
  const [createAccount, { loading: loadingAddingAccInfo }] = useMutation(
    CREATE_PAYMENT_MODE_MUTATION,
    {
      errorPolicy: "all",
    }
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const response = await createAccount({
      variables: data,
    });

    if (response) {
      const userId =
        response &&
        response.data &&
        response.data.createBankAccount &&
        response.data.createBankAccount._id;

      if (userId) {
        reset();
        refetchAccountDetails();
        toast.success("Account has been created successfully.");
      }
    }

    //Show errors if any
    transformGQLErrors(response).map((e) => toast.error(e));
  };
  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader className="card-header border">
            <div className="d-flex align-items-center">
              <h5 className="card-title mb-0 flex-grow-1">
                Add Account Details
              </h5>
              <div className="flex-shrink-0"></div>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg={4}>
                <div className="mb-3">
                  <Label className="form-label">Payment Method</Label>
                  <select
                    className="form-select"
                    {...register("paymentMethod")}
                    onChange={(e) => {
                      setSelectedPaymentMethod(e.target.value);
                    }}
                  >
                    <option value="paypal">Paypal</option>
                    <option value="payoneer">Payoneer</option>
                    <option value="bank">Bank</option>
                  </select>
                  <div className="invalid-feedback">
                    {errors.paymentMethod?.message}
                  </div>
                </div>
              </Col>
              {selectedPaymentMethod !== "bank" && (
                <Col lg={4}>
                  <div className="mb-3">
                    <Label className="form-label text-capitalize">
                      {selectedPaymentMethod} Email
                    </Label>
                    <input
                      type="email"
                      placeholder="Email"
                      {...register("email")}
                      className={`form-control  ${
                        errors.email ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.email?.message}
                    </div>
                  </div>
                </Col>
              )}
              {selectedPaymentMethod === "bank" && (
                <Fragment>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label">Bank Name</Label>
                      <input
                        type="text"
                        placeholder="Bank Name"
                        {...register("bankName")}
                        className={`form-control  ${
                          errors.bankName ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.bankName?.message}
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label">Bank Address</Label>
                      <input
                        type="text"
                        placeholder="Bank Address"
                        {...register("bankAddress")}
                        className={`form-control  ${
                          errors.bankAddress ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.bankAddress?.message}
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label">Account Type</Label>
                      <select
                        className="form-select"
                        {...register("accountType")}
                      >
                        <option value="checking">Checking</option>
                        <option value="saving">Saving</option>
                      </select>
                      <div className="invalid-feedback">
                        {errors.accountType?.message}
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label">Account Name</Label>
                      <input
                        type="text"
                        placeholder="Account Name"
                        {...register("accountName")}
                        className={`form-control  ${
                          errors.accountName ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.accountName?.message}
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label">Account Number</Label>
                      <input
                        type="text"
                        placeholder="Account Number"
                        {...register("accountNumber")}
                        className={`form-control  ${
                          errors.accountNumber ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.accountNumber?.message}
                      </div>
                    </div>
                  </Col>
                </Fragment>
              )}
            </Row>
          </CardBody>
        </Card>

        <div className="text-end mb-4">
          <button
            className="btn btn-primary w-sm"
            disabled={loadingAddingAccInfo}
          >
            Save
          </button>
        </div>
      </Form>
    </Fragment>
  );
};
