import { gql } from "@apollo/client";

export const GET_TRANSACTIONS = gql`
  query (
    $filters: FiltersExpression!
    $pagination: PaginationInput!
    $orderBy: OrderByInput!
  ) {
    transactions(
      filters: $filters
      pagination: $pagination
      orderBy: $orderBy
    ) {
      count
      transactions {
        _id
        commission
        saleAmount
        status
        transactionDate
        referenceId
        currency
        reference {
          ref1
          ref2
          ref3
          ref4
          ref5
        }
        offer {
          offerName
          referenceId
          logo
        }
        user {
          firstName
          lastName
          website
        }
        invoice {
          invoiceNo
        }
      }
    }
  }
`;

export const DASHBOARD_SALES_STATS = gql`
  query ($filters: FiltersExpression!) {
    dashboardSalesStats(filters: $filters) {
      currency
      totalSales
      totalSaleAmount
      totalCommission
    }
  }
`;

export const DASHBOARD_BAR_GRAPH = gql`
  query ($filters: FiltersExpression!) {
    dashboardBarGraph(filters: $filters) {
      currency
      totalSales
      totalSaleAmount
      totalCommission
      date
    }
  }
`;

export const PERFORMANCE_REPORT = gql`
  query ($filters: FiltersExpression!) {
    performanceReport(filters: $filters) {
      date
      offerName
      totalVisits
      reportsData {
        currency
        totalSales
        totalSaleAmount
        totalPayable
      }
    }
  }
`;

export const TRANSACTIONS_STATS = gql`
  query ($filters: FiltersExpression!) {
    transactionsStats(filters: $filters) {
      currency
      totalSales
      totalPayable
    }
  }
`;
