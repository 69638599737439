import Select from "react-select";
import countries from "_data/countries";
import { transformDataIntoValueLabel } from "_utils";

export const CountriesDropdown = (props) => {
  const { onCountryChange } = props;

  const handleChangeCountry = (inputValue) => {
    const val = inputValue ? inputValue.value : "all";
    onCountryChange(val);
  };

  const countriesMod = countries.map((country) => {
    return {
      ...country,
      value: `${country.name.toLowerCase()}`,
    };
  });

  return (
    <Select
      placeholder={`Select Country`}
      isClearable={true}
      defaultOptions={false}
      options={transformDataIntoValueLabel(countriesMod, "name", "value")}
      onChange={handleChangeCountry}
    />
  );
};
