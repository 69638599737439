import { KEY_AUTH_TOKEN, userRole } from "_constants";
import { useUserInfo } from "_hooks/Shared";
import { transactionsFilter } from "_utils";
import { Fragment, useState } from "react";

const API_URL = process.env.REACT_APP_API_URL;

export const DownloadTransactionsButton = (props) => {
  const { userInfo } = useUserInfo();
  const [loading, setLoading] = useState(false);

  const userType = userInfo.roles.find(Boolean);
  const route = [userRole.ADMIN, userRole.EDITOR].includes(userType)
    ? "download-transactions-admin"
    : "download-transactions";
  const handleDownloadBtn = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem(KEY_AUTH_TOKEN);
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: token ? `Bearer ${token}` : "",
        },
        body: JSON.stringify(transactionsFilter(null, null, props.filters)),
      };
      const response = await fetch(
        `${API_URL}/api/v1/transactions/${route}`,
        options
      );
      setLoading(false);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();

      // Create a Blob URL for the file
      const url = window.URL.createObjectURL(blob);

      // Create a download link and trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "transactions.xlsx";
      a.click();

      // Release the Blob URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading Excel:", error);
    }
  };
  return (
    <Fragment>
      <button
        className="btn btn-secondary"
        onClick={handleDownloadBtn}
        disabled={loading}
      >
        <i className="ri-file-excel-2-line me-1 align-bottom"></i>
        Download xls
      </button>
    </Fragment>
  );
};
