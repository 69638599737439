import { Fragment } from "react";
import { APP_NAME } from "_constants";

export const CopyRightContent = () => {
  return (
    <Fragment>
      Copyright © {new Date().getFullYear()} {APP_NAME}. All rights reserved
    </Fragment>
  );
};
