import { Fragment } from "react";
import {
  NoRecordFoundTable,
  TransactionStatus,
  UserInfoInTable,
  YesNoStatus,
} from "_components";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { userStatus } from "_constants";
import { useLogin } from "_hooks/Shared";
import { useMutation } from "@apollo/client";
import {
  SEND_EMAIL_CONFIRMATION_BY_ID_MUTATION,
  UPDATE_USER_STATUS_BY_ID_MUTATION,
} from "_queries/Shared";
import { toast } from "react-toastify";
import { timestampToDate } from "_utils";

const columns = [
  { label: "Title", class: "min-w-250px" },
  { label: "Email", class: "min-w-100px" },
  { label: "Phone", class: "min-w-200px" },
  { label: "Email Confirmed", class: "min-w-100px" },
  { label: "Joined Date", class: "min-w-100px" },
  { label: "Status", class: "min-w-100px" },
  { label: "Action", class: "text-center min-w-200px" },
];

export const ListingTable = ({ data, refetch }) => {
  const [sendConfirmationEmailToUser] = useMutation(
    SEND_EMAIL_CONFIRMATION_BY_ID_MUTATION,
    {
      errorPolicy: "all",
    }
  );
  const [updateUserStatus] = useMutation(UPDATE_USER_STATUS_BY_ID_MUTATION, {
    errorPolicy: "all",
  });
  const loginAndNavigate = useLogin();

  const handleLoginButton = (id) => {
    loginAndNavigate(id);
  };

  const handleSendConfirmationEmail = async (id) => {
    const response = await sendConfirmationEmailToUser({
      variables: { id },
    });

    if (response) {
      const data =
        response && response.data && response.data.sendConfirmationEmail;

      if (data) {
        toast.success(
          "Email confirmation has been sent to user email address."
        );
      }
    }
  };

  const handleUpdateStatus = async (id) => {
    const response = await updateUserStatus({
      variables: { id, status: "active" },
    });

    if (response) {
      const data =
        response &&
        response.data &&
        response.data.updateStatus &&
        response.data.updateStatus._id;

      if (data) {
        refetch();
        toast.success("User status has been changed successfully.");
      }
    }
  };

  return (
    <Fragment>
      <table className="table align-middle table-nowrap mb-0">
        <thead className="table-light text-muted">
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody className="list form-check-all">
          {data &&
            data.map((user) => (
              <tr key={user._id}>
                <td>
                  <UserInfoInTable user={user} />
                </td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>
                  <YesNoStatus
                    status={user.isEmailConfirmed === "true" ? "YES" : "NO"}
                  />
                </td>
                <td>{timestampToDate(user.createdAt)}</td>
                <td>
                  <TransactionStatus status={user.status} />
                </td>
                <td>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      tag="button"
                      className="btn-soft-secondary btn-sm"
                    >
                      <i className="ri-more-fill"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleLoginButton(user._id)}>
                        <i className="ri-shield-user-line align-bottom me-2 text-muted"></i>
                        Login
                      </DropdownItem>
                      {[userStatus.PENDING, userStatus.BLOCKED].includes(
                        user.status
                      ) && (
                        <DropdownItem
                          onClick={() => handleUpdateStatus(user._id)}
                        >
                          <i className="ri-user-unfollow-line align-bottom me-2 text-muted"></i>
                          Active User
                        </DropdownItem>
                      )}
                      {user.isEmailConfirmed === "false" && (
                        <DropdownItem
                          onClick={() => handleSendConfirmationEmail(user._id)}
                        >
                          <i className="ri-user-unfollow-line align-bottom me-2 text-muted"></i>
                          Send Email Confirmation
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {data && data.length === 0 && <NoRecordFoundTable />}
    </Fragment>
  );
};
