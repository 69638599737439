import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD_MUTATION } from "_queries/Shared";
import { transformGQLErrors } from "_utils";
import { toast } from "react-toastify";
import { Link, useSearchParams } from "react-router-dom";
import { Fragment } from "react";
import { ParticlesAuth } from "_components";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Label,
  Row,
} from "reactstrap";

const AWS_S3_BUCKET_URL = process.env.REACT_APP_AWS_S3_BUCKET_URL;

const schema = yup.object({
  password: yup.string().required("Password is required."),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Both passwords need to be the same"),
});

export const ResetPassword = () => {
  const [searchParams] = useSearchParams();

  const [passwordReset, { loading }] = useMutation(RESET_PASSWORD_MUTATION, {
    errorPolicy: "all",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const variables = {
      ...data,
      token: searchParams.get("token"),
      ref: searchParams.get("ref"),
    };
    const response = await passwordReset({
      variables: variables,
    });

    if (response) {
      const isPasswordReset =
        response && response.data && response.data.resetPassword;

      if (isPasswordReset) {
        reset();
        toast.success(
          "Password has been updated successfully. Please login to continue."
        );
      }
    }

    //Show errors if any
    transformGQLErrors(response).map((e) => toast.error(e));
  };

  return (
    <Fragment>
      <div className="auth-page-wrapper pt-5">
        <ParticlesAuth>
          <div className="auth-page-content">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="text-center mt-sm-5 mb-4 text-white-50">
                    <div>
                      <Link to="/" className="d-inline-block auth-logo">
                        <img
                          src={`${AWS_S3_BUCKET_URL}/app-logos/logo-light.png`}
                          alt=""
                          height="20"
                        />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="mt-4">
                    <CardBody className="p-4">
                      <div className="text-center mt-2">
                        <h5 className="text-primary">Create new password</h5>
                        <p className="text-muted">
                          Your new password must be different from previous used
                          password.
                        </p>
                      </div>

                      <div className="p-2">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                          <div className="mb-4">
                            <Label className="form-label">Password</Label>
                            <input
                              type="password"
                              placeholder="Enter password"
                              {...register("password")}
                              className={`form-control ${
                                errors.password ? "is-invalid" : ""
                              }`}
                            />
                            {errors.password?.message ? (
                              <FormFeedback type="invalid">
                                <div>{errors.password.message}</div>
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-4">
                            <Label className="form-label">
                              Confirm Password
                            </Label>
                            <input
                              type="password"
                              placeholder="Confirm password"
                              {...register("confirmPassword")}
                              className={`form-control ${
                                errors.confirmPassword ? "is-invalid" : ""
                              }`}
                            />
                            {errors.confirmPassword?.message ? (
                              <FormFeedback type="invalid">
                                <div>{errors.confirmPassword.message}</div>
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="text-center mt-4">
                            <button
                              className="btn btn-primary w-100"
                              type="submit"
                              disabled={loading}
                            >
                              Reset Password
                            </button>
                          </div>
                        </Form>
                      </div>
                    </CardBody>
                  </Card>

                  <div className="mt-4 text-center">
                    <p className="mb-0">
                      {`Wait, I remember my password... `}
                      <Link
                        to={`/login`}
                        className="fw-semibold text-primary text-decoration-underline"
                      >
                        Click here
                      </Link>
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </ParticlesAuth>
      </div>
    </Fragment>
  );
};
