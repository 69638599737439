import { useQuery } from "@apollo/client";
import { DashboardFilters } from "containers/Affiliate/Dashboard/DashboardFilters";
import { Fragment, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { DASHBOARD_SALES_STATS } from "_queries/Admin";
import { SalesBlocks } from "./SalesBlocks";
import { PieChartAllCurrencySales } from "./PieChartAllCurrencySales";
import { endDateOfCurrentMonth, transactionsFilter } from "_utils";
import { BarGraph } from "./BarGraph";
import { SalesGrid } from "./SalesGrid";
import { DEFAULT_START_DATE } from "_constants";
import { GeneratedInvoiceWidget } from "./GeneratedInvoiceWidget";

export const Dashboard = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState("usd");

  const {
    data: dataAllCurrencyData,
    loading: loadingAllCurrencyData,
    refetch,
  } = useQuery(DASHBOARD_SALES_STATS, {
    variables: transactionsFilter(null, null, {
      transactionDate: { start: startDate, end: endDate },
    }),
    fetchPolicy: "no-cache",
  });
  const allCurrencyData = dataAllCurrencyData?.dashboardSalesStatsAdmin || [];
  const selectedCurrencyData = allCurrencyData.find(
    (x) => x.currency === selectedCurrency
  );
  if (allCurrencyData.length > 0 && !selectedCurrencyData) {
    const { currency } = allCurrencyData.find(Boolean);
    setSelectedCurrency(currency);
  }

  useEffect(() => {
    refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  return (
    <Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">Homepage</h4>

            <DashboardFilters
              allCurrencyData={allCurrencyData}
              selectedCurrency={selectedCurrency}
              onStartDateChange={(startDate) => {
                setStartDate(startDate);
              }}
              onEndDateChange={(endDate) => {
                setEndDate(endDate);
              }}
              onCurrencyChange={(currency) => {
                setSelectedCurrency(currency);
              }}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col className="col-xxl-12">
          <SalesBlocks
            startDate={startDate}
            endDate={endDate}
            loadingAllCurrencyData={loadingAllCurrencyData}
            selectedCurrencyData={selectedCurrencyData}
            selectedCurrency={selectedCurrency}
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-xxl-3">
          <PieChartAllCurrencySales
            loadingAllCurrencyData={loadingAllCurrencyData}
            allCurrencyData={allCurrencyData}
          />
        </Col>
        <Col className="col-xxl-9 order-xxl-0 order-first">
          <BarGraph
            startDate={startDate}
            endDate={endDate}
            selectedCurrency={selectedCurrency}
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-xxl-3">
          <SalesGrid
            startDate={startDate}
            endDate={endDate}
            status="pending"
            title="Pending Profit"
          />
          <SalesGrid
            startDate={DEFAULT_START_DATE}
            endDate={endDateOfCurrentMonth()}
            status="awaiting_payment"
            title="Awaiting Payment"
            slug="awaiting-payment"
          />
          <GeneratedInvoiceWidget title="Generated Invoices" />
        </Col>
        <Col className="col-xxl-9 order-xxl-0 order-first"></Col>
      </Row>
    </Fragment>
  );
};
