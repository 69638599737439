import { useApolloClient, useQuery } from "@apollo/client";
import { varAuthToken } from "./varAuthToken";
import { gql } from "@apollo/client";
import { KEY_AUTH_TOKEN } from "_constants";

const GET_AUTH_TOKEN = gql`
  query GetAuthToken {
    authToken @client {
      token
    }
  }
`;

const saveToLocalStorage = () => {
  window.localStorage.setItem(KEY_AUTH_TOKEN, varAuthToken());
};

const removeFromLocalStorage = () => {
  window.localStorage.removeItem(KEY_AUTH_TOKEN);
};

export const useAuthToken = () => {
  const {
    data: { authToken },
  } = useQuery(GET_AUTH_TOKEN);

  const setAuthToken = (authToken) => {
    varAuthToken(authToken);
    saveToLocalStorage();
  };

  const removeAuthToken = () => {
    removeFromLocalStorage();
  };

  return [authToken, setAuthToken, removeAuthToken];

  // return [
  //   authToken,
  //   updateAuthToken(authToken) {
  //     varAuthToken(authToken);
  //     saveAuthToken();
  //   },
  //   removeAuthToken() {
  //     removeAuthToken();
  //   },
  // ];
};

export const useLogout = () => {
  const [removeAuthToken] = useAuthToken();
  const apolloClient = useApolloClient();

  const logout = async () => {
    await apolloClient.clearStore(); // we remove all information in the store
    removeAuthToken();
  };
  return logout;
};
