import { Fragment, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Filters } from "./Filters";
import { Listing } from "./Listing";
import { useQuery } from "@apollo/client";
import { GET_ALL_USERS } from "_queries/Admin";
import { usersFilter } from "_utils";
import { DEFAULT_PER_PAGE } from "_constants";

export const Users = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [filters, setFilters] = useState(null);

  const { data, loading, refetch } = useQuery(GET_ALL_USERS, {
    variables: usersFilter(currentPage, perPage, filters),
  });

  useEffect(() => {
    refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filters]);

  const paginatedData = data?.users?.users;
  const numberOfTotalPages = data?.users.count;

  const handleFilterChange = (key, value) => {
    setFilters(() => {
      return {
        ...filters,
        [key]: value,
      };
    });
  };

  const filtersProps = {
    setCurrentPage,
    handleFilterChange,
  };

  const listingProps = {
    numberOfTotalPages,
    paginatedData,
    setCurrentPage,
    setPerPage,
    currentPage,
    perPage,
    loading,
    refetch,
  };

  return (
    <Fragment>
      <Row id="filter">
        <Col lg={12}>
          <Filters {...filtersProps} />
        </Col>
      </Row>
      <Row id="listing">
        <Col lg={12}>
          <Listing {...listingProps} />
        </Col>
      </Row>
    </Fragment>
  );
};
