import { Fragment } from "react";
import { Container } from "reactstrap";
import { Menu, Footer, Header } from "_components";

export const Layout = ({ children }) => {
  return (
    <Fragment>
      <div id="layout-wrapper">
        <Header />
        <Menu />
        <div className="main-content">
          <div className="page-content">
            <Container fluid>{children}</Container>
          </div>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
};
