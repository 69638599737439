export const transformGQLErrors = (response) => {
  const errorsArray = [];
  response.errors?.forEach((graphQLError) => {
    if (Array.isArray(graphQLError.extensions.response.message)) {
      graphQLError.extensions.response.message.forEach((message) => {
        const { constraints } = message;
        const [constraint] = Object.keys(constraints);
        errorsArray.push(constraints[constraint]);
      });
    } else {
      errorsArray.push(graphQLError.extensions.response.message);
    }
  });

  return errorsArray;
};
