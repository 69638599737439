import { Navigate, useOutlet } from "react-router-dom";
import { history } from "_helpers";
import { userRole } from "_constants";
import { useUserInfo } from "_hooks/Shared";
import { Fragment } from "react";
import { Layout } from "_components";

export const ProtectedAffiliateLayout = () => {
  const outlet = useOutlet();
  const { userInfo } = useUserInfo();

  if (!userInfo || !userInfo.roles.includes(userRole.AFFILIATE)) {
    return <Navigate to="/login" state={{ from: history.location }} />;
  }

  return (
    <Fragment>
      <Layout>{outlet}</Layout>
    </Fragment>
  );
};
