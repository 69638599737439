import Select from "react-select";
import currencies from "_data/currencies";
import { transformDataIntoValueLabel } from "_utils";

export const CurrenciesDropdown = (props) => {
  const { onCurrencyChange, defaultValue, isClearable = true } = props;
  let defaultValueObj = null;

  const handleChangeCurrency = (inputValue) => {
    const val = inputValue ? inputValue.value : "all";
    onCurrencyChange(val.toLowerCase());
  };

  if (defaultValue) {
    const isCurrencyFound = currencies.find(
      (x) => x.cc.toLowerCase() === defaultValue
    );
    if (isCurrencyFound) {
      defaultValueObj = transformDataIntoValueLabel(
        [isCurrencyFound],
        "cc",
        "cc"
      )[0];
    }
  }

  return (
    <Select
      placeholder={`Select Currency`}
      isClearable={isClearable}
      defaultValue={defaultValueObj}
      menuPortalTarget={document.body}
      options={transformDataIntoValueLabel(currencies, "cc", "cc")}
      onChange={handleChangeCurrency}
    />
  );
};
