import { useApolloClient } from "@apollo/client";
import AsyncSelect from "react-select/async";
import { SEARCH_OFFERS as SEARCH_OFFERS_AFFILIATE } from "_queries/Affiliate";
import { SEARCH_OFFERS as SEARCH_OFFERS_ADMIN } from "_queries/Admin";
import { offersFilter, transformDataIntoValueLabel } from "_utils";
import { useUserInfo } from "_hooks/Shared";
import { userRole } from "_constants";
import { useSearchParams } from "react-router-dom";

export const OffersDropdown = (props) => {
  const { onOfferChange } = props;
  const client = useApolloClient();
  const { userInfo } = useUserInfo();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  const userType = userInfo.roles.find(Boolean);
  const querySearchOffers = [userRole.ADMIN].includes(userType)
    ? SEARCH_OFFERS_ADMIN
    : SEARCH_OFFERS_AFFILIATE;

  const styles = {
    control: (base) => ({
      ...base,
      minHeight: 38,
      marginTop: 0,
      //width: 400,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  const loadOptionsIndexes = (inputValue) => {
    if (inputValue) {
      const graphqlQueryExpression = {
        query: querySearchOffers,
        variables: offersFilter(null, null, {
          searchTerm: inputValue,
          status: status,
        }),
      };

      return new Promise((resolve) => {
        client.query(graphqlQueryExpression).then((response) => {
          let offers = null;
          for (let key in response.data) {
            if (response.data.hasOwnProperty(key)) {
              offers = response.data[key].map((offer) => {
                return {
                  ...offer,
                  offerNameWithRefer: `${offer.offerName} - ${offer.referenceId}`,
                };
              });
            }
          }
          resolve(
            transformDataIntoValueLabel(offers, "offerNameWithRefer", "_id")
          );
        });
      });
    }
  };

  const handleChangeOffer = (inputValue) => {
    const val = inputValue ? inputValue.value : "all";
    onOfferChange(val);
  };
  return (
    <AsyncSelect
      styles={styles}
      placeholder={`Search Offer`}
      isClearable={true}
      defaultOptions={false}
      loadOptions={loadOptionsIndexes}
      onChange={handleChangeOffer}
    />
  );
};
