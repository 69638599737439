import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Collapse, Container } from "reactstrap";
import { useUserInfo } from "_hooks/Shared";
import { routes } from "_routes";
import { pagePathWithParams } from "_utils";

const AWS_S3_BUCKET_URL = process.env.REACT_APP_AWS_S3_BUCKET_URL;

export const Menu = () => {
  const [selectedMenu, setSelectedMenu] = useState("dashboard");
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const { userInfo } = useUserInfo();
  const userRole = userInfo.roles.find(Boolean);
  const pages = routes[userRole];

  const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
    if (
      document.documentElement.getAttribute("data-sidebar-size") === "sm-hover"
    ) {
      document.documentElement.setAttribute(
        "data-sidebar-size",
        "sm-hover-active"
      );
    } else if (
      document.documentElement.getAttribute("data-sidebar-size") ===
      "sm-hover-active"
    ) {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    } else {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    }
  };

  const onClickMenu = (path) => {
    setSelectedMenu(path);
    setSelectedSubMenu(null);
  };

  const onClickSubMenu = (childPath, parentPath) => {
    setSelectedMenu(parentPath);
    setSelectedSubMenu(childPath);
  };

  return (
    <div className="app-menu navbar-menu">
      <div className="navbar-brand-box">
        <Link to="/" className="logo logo-light">
          <span className="logo-sm">
            <img
              src={`${AWS_S3_BUCKET_URL}/app-logos/logo-light.png`}
              alt=""
              height="40"
            />
          </span>
          <span className="logo-lg">
            <img
              src={`${AWS_S3_BUCKET_URL}/app-logos/logo-light.png`}
              alt=""
              height="70"
            />
          </span>
        </Link>
        <button
          onClick={addEventListenerOnSmHoverMenu}
          type="button"
          className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
        >
          <i className="ri-record-circle-line"></i>
        </button>
      </div>

      <div id="scrollbar">
        <Container fluid>
          <div id="two-column-menu"></div>
          <ul className="navbar-nav" id="navbar-nav">
            <li className="menu-title">
              <span data-key="t-menu">Menu</span>
            </li>

            {(pages || []).map((page) => {
              if (!page.showInMenu) {
                return null; // Skip rendering if showInMenu is false
              }
              return (
                <Fragment key={page.id}>
                  {
                    <li className="nav-item">
                      <Link
                        className={`nav-link menu-link ${
                          selectedMenu === page.path && "active"
                        }`}
                        to={page.path ? pagePathWithParams(page) : "/#"}
                        data-bs-toggle={
                          page.children.length > 0 ? "collapse" : ""
                        }
                        onClick={(e) => {
                          onClickMenu(pagePathWithParams(page));
                        }}
                      >
                        <i className={`bx ${page.icon}`}></i>
                        <span>{page.label}</span>
                      </Link>
                      {page.children.length > 0 && (
                        <Collapse className="menu-dropdown collapse">
                          <ul className="nav nav-sm flex-column test">
                            {page.children.map((child) => {
                              if (!child.showInMenu) {
                                return null; // Skip rendering if showInMenu is false for the child
                              }
                              return (
                                <li className="nav-item" key={child.id}>
                                  <Link
                                    className={`nav-link ${
                                      selectedSubMenu ===
                                        pagePathWithParams(child) && "active"
                                    }`}
                                    to={
                                      child.path
                                        ? pagePathWithParams(child)
                                        : "/#"
                                    }
                                    onClick={() =>
                                      onClickSubMenu(
                                        pagePathWithParams(child),
                                        page.path
                                      )
                                    }
                                  >
                                    {child.label}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </Collapse>
                      )}
                    </li>
                  }
                </Fragment>
              );
            })}
          </ul>
        </Container>
      </div>

      <div className="sidebar-background"></div>
    </div>
  );
};
