import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import {
  ApolloClient,
  createHttpLink,
  ApolloProvider,
  from,
} from "@apollo/client";
import { cache } from "_storage/";
import { setContext } from "@apollo/client/link/context";
import { App } from "./App";
import { KEY_AUTH_TOKEN } from "_constants";
import { toast, ToastContainer } from "react-toastify";
import { onError } from "@apollo/client/link/error";
import { logoutWithoutHook } from "_hooks/Shared";

const container = document.getElementById("root");
const root = createRoot(container);
const API_URL = process.env.REACT_APP_API_URL;

const httpLink = createHttpLink({
  uri: `${API_URL}/graphql`,
});
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(KEY_AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      switch (message) {
        case "Unauthorized":
          toast.error("Session has been expired. Please login to continue");
          setTimeout(() => {
            logoutWithoutHook();
          }, 1000);
          break;
        default:
          break;
      }
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});
const client = new ApolloClient({
  //link: authLink.concat(httpLink),
  link: from([errorLink, authLink, httpLink]),
  cache,
});

root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
      <ToastContainer position="top-right" />
    </BrowserRouter>
  </ApolloProvider>
);
