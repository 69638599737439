import { Fragment } from "react";

export const ListingCheckboxSingle = ({ value, onCheckboxChange }) => {
  const handleCheckboxChange = (event) => {
    const checkedValue = event.target.value;
    const isChecked = event.target.checked;

    onCheckboxChange([{ isChecked, isCheckedValue: checkedValue }]);
  };

  return (
    <Fragment>
      <input
        type="checkbox"
        className="taskCheckBox form-check-input"
        value={value}
        onChange={handleCheckboxChange}
      />
    </Fragment>
  );
};
