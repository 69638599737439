export const usersFilter = (currentPage, perPage, filters) => {
  const filtersObj = {
    fields: [],
  };
  if (filters && filters.user && filters.user !== "all") {
    filtersObj.fields.push({
      field: "_id",
      value: filters.user,
      type: "mongo",
    });
  }
  const skip = (currentPage - 1) * perPage;
  const take = perPage;
  const orderBy = { field: "createdAt", value: "desc" };
  return {
    filters: filtersObj,
    pagination: { take, skip },
    orderBy,
  };
};
