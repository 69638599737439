import moment from "moment/moment";

export const queryFilters = (filters) => {
  let startDate = moment().startOf("month").format("YYYY-MM-DD");
  let endDate = moment().endOf("month").format("YYYY-MM-DD");
  const filtersObj = {
    fields: [],
  };

  if (filters && filters.transactionDate) {
    const { start, end } = filters.transactionDate;
    if (start && end) {
      startDate = start;
      endDate = end;
    }
  }
  if (filters && filters.status) {
    filtersObj.fields.push({
      field: "status",
      value: JSON.stringify([filters.status]),
      type: "normal",
    });
  }
  filtersObj.fields.push({
    field: "startDate",
    value: startDate,
    type: "normal",
  });
  filtersObj.fields.push({
    field: "endDate",
    value: endDate,
    type: "normal",
  });

  return {
    filters: filtersObj,
  };
};
