import { useApolloClient } from "@apollo/client";
import AsyncSelect from "react-select/async";
import { SEARCH_USERS } from "_queries/Admin";
import { offersFilter, transformDataIntoValueLabel } from "_utils";
import { useSearchParams } from "react-router-dom";

export const UsersDropdown = (props) => {
  const { onUserChange } = props;
  const client = useApolloClient();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  const styles = {
    control: (base) => ({
      ...base,
      minHeight: 38,
      marginTop: 0,
      //width: 400,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  const loadOptionsIndexes = (inputValue) => {
    if (inputValue) {
      const graphqlQueryExpression = {
        query: SEARCH_USERS,
        variables: offersFilter(null, null, {
          searchTerm: inputValue,
          status: status,
        }),
      };

      return new Promise((resolve) => {
        client.query(graphqlQueryExpression).then((response) => {
          let offers = null;
          for (let key in response.data) {
            if (response.data.hasOwnProperty(key)) {
              offers = response.data[key].map((offer) => {
                return {
                  ...offer,
                  fullName: `${offer.firstName} ${offer.lastName} | ${offer.referenceId}`,
                };
              });
            }
          }
          resolve(transformDataIntoValueLabel(offers, "fullName", "_id"));
        });
      });
    }
  };

  const handleChangeOffer = (inputValue) => {
    const val = inputValue ? inputValue.value : "all";
    onUserChange(val);
  };
  return (
    <AsyncSelect
      styles={styles}
      placeholder={`Search User`}
      isClearable={true}
      defaultOptions={false}
      loadOptions={loadOptionsIndexes}
      onChange={handleChangeOffer}
    />
  );
};
