import { KEY_AUTH_TOKEN } from "_constants";
import { useState } from "react";

const API_URL = process.env.REACT_APP_API_URL;

export const DownloadOffersButton = () => {
  const [loading, setLoading] = useState(false);
  const downloadExcel = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem(KEY_AUTH_TOKEN);
      const options = {
        method: "POST",
        headers: {
          authorization: token ? `Bearer ${token}` : "",
        },
      };
      const response = await fetch(
        `${API_URL}/api/v1/advertisers/download-advertisers`,
        options
      );
      setLoading(false);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();

      // Create a Blob URL for the file
      const url = window.URL.createObjectURL(blob);

      // Create a download link and trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "advertisers.xlsx";
      a.click();

      // Release the Blob URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading Excel:", error);
    }
  };
  return (
    <button
      type="button"
      className="btn btn-secondary"
      disabled={loading}
      onClick={downloadExcel}
    >
      Export xls
    </button>
  );
};
