import { useQuery } from "@apollo/client";
import { GET_ALL_CATEGORIES } from "_queries/Shared";

export function useCategories() {
  const { data, loading, error } = useQuery(GET_ALL_CATEGORIES);
  return {
    categories: data?.categories,
    loading,
    error,
  };
}
