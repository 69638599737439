import { useQuery } from "@apollo/client";
import { varUserInfo } from "_storage";
import { gql } from "@apollo/client";
import { KEY_USER_INFO } from "_constants";

const GET_USER_INFO = gql`
  query GetAuthToken {
    userInfo @client {
      _id
      firstName
      lastName
      referenceId
      roles
    }
  }
`;

export const removeUserFromLocalStorage = () => {
  window.localStorage.removeItem(KEY_USER_INFO);
};

export const useUserInfo = () => {
  const {
    data: { userInfo },
  } = useQuery(GET_USER_INFO);

  const setUserInfo = (user) => {
    varUserInfo(user);
    window.localStorage.setItem(KEY_USER_INFO, JSON.stringify(varUserInfo()));
  };

  const removeUserInfo = () => {
    removeUserFromLocalStorage();
  };

  return { userInfo, setUserInfo, removeUserInfo };
};
