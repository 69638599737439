import { useLazyQuery, useMutation } from "@apollo/client";
import { useAuthToken } from "./useAuthToken";
import { useUserInfo } from "./useUserInfo";
import { GET_PROFILE, LOGIN_BY_ID_MUTATION } from "_queries/Shared";
import { useNavigate } from "react-router-dom";
import { HOME_PAGE_AFFILIATE } from "_constants";

export const useLogin = () => {
  const navigate = useNavigate();
  const [login] = useMutation(LOGIN_BY_ID_MUTATION, {
    errorPolicy: "all",
  });
  const [getUser] = useLazyQuery(GET_PROFILE, {
    fetchPolicy: "no-cache",
  });
  const { setAuthToken, removeAuthToken } = useAuthToken();
  const { setUserInfo, removeUserInfo } = useUserInfo();

  const loginAndNavigate = async (id) => {
    const response = await login({
      variables: { id },
    });

    if (response) {
      const accessToken =
        response &&
        response.data &&
        response.data.logInById &&
        response.data.logInById.access_token;

      if (accessToken) {
        removeAuthToken();
        removeUserInfo();
        setAuthToken(accessToken);
        const userResp = await getUser();

        if (userResp) {
          const userInfo = userResp && userResp.data && userResp.data.me;

          if (userInfo) {
            setUserInfo(userInfo);
            navigate(HOME_PAGE_AFFILIATE);
          }
        } else {
          removeAuthToken();
        }
      }
    }
  };

  return loginAndNavigate;
};
