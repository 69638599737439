import { Col, Row } from "reactstrap";
import { Fragment, useEffect, useState } from "react";
import { Listing } from "./Listing";
import { Filters } from "./Filters";
import { GET_TRANSACTIONS, TRANSACTIONS_STATS } from "_queries/Affiliate";
import { useQuery } from "@apollo/client";
import { transactionsFilter } from "_utils";
import { DEFAULT_PER_PAGE } from "_constants";
import { StatWidgets } from "_components";
import { useSearchParams } from "react-router-dom";

const blockWidgets = [
  {
    id: 1,
    label: "Orders",
    icon: "ri-numbers-fill",
    decimals: "",
    prefix: "",
    key: "totalSales",
  },
  {
    id: 2,
    label: "Tentative Commission",
    icon: "ri-award-fill",
    decimals: 2,
    prefix: "$",
    key: "totalPayable",
  },
];

export const Transactions = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [filters, setFilters] = useState(null);
  const [searchParams] = useSearchParams();
  const invoice_no = searchParams.get("invoice_no");

  useEffect(() => {
    handleFilterChange("invoice_no", invoice_no);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice_no]);

  const { data, loading } = useQuery(GET_TRANSACTIONS, {
    variables: transactionsFilter(currentPage, perPage, filters),
  });

  const { data: dataTranStats, loading: loadingTranStats } = useQuery(
    TRANSACTIONS_STATS,
    {
      variables: transactionsFilter(null, null, filters),
      fetchPolicy: "no-cache",
    }
  );

  const paginatedData = data?.transactions?.transactions;
  const numberOfTotalPages = data?.transactions.count;
  const dataWidgets = dataTranStats?.transactionsStats;

  const handleFilterChange = (key, value) => {
    setFilters(() => {
      return {
        ...filters,
        [key]: value,
      };
    });
  };

  const statsProps = {
    data: dataWidgets,
    loading: loadingTranStats,
    blocks: blockWidgets,
  };

  const filtersProps = {
    setCurrentPage,
    handleFilterChange,
    filters,
  };

  const listingProps = {
    numberOfTotalPages,
    paginatedData,
    setCurrentPage,
    setPerPage,
    currentPage,
    perPage,
    loading,
  };

  return (
    <Fragment>
      <Row id="stats">
        <StatWidgets {...statsProps} />
      </Row>
      <Row id="filter">
        <Col lg={12}>
          <Filters {...filtersProps} />
        </Col>
      </Row>
      <Row id="listing">
        <Col lg={12}>
          <Listing {...listingProps} />
        </Col>
      </Row>
    </Fragment>
  );
};
