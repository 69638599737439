import Select from "react-select";
import { useCategories } from "_hooks/Shared";
import { transformDataIntoValueLabel } from "_utils";

export const CategoriesDropdown = (props) => {
  const { onCategoryChange } = props;
  const { categories } = useCategories();

  const handleChangeCategory = (inputValue) => {
    const val = inputValue ? inputValue.value : "all";
    onCategoryChange(val);
  };
  return (
    <Select
      placeholder={`Select Category`}
      isClearable={true}
      defaultOptions={false}
      options={transformDataIntoValueLabel(categories, "categoryName", "_id")}
      onChange={handleChangeCategory}
    />
  );
};
