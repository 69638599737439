import { Pagination, PerPageDropdown, Loader } from "_components";
import { Fragment } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { ListingTable } from "./ListingTable";
export const Listing = ({
  numberOfTotalPages,
  paginatedData,
  setCurrentPage,
  setPerPage,
  currentPage,
  perPage,
  loading,
  refetch,
}) => {
  return (
    <Fragment>
      <Card>
        <CardHeader className="card-header border-0">
          <div className="d-flex align-items-center">
            <h5 className="card-title mb-0 flex-grow-1">All Users</h5>
            <div className="flex-shrink-0"></div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="table-responsive table-card mb-4 table-transaction">
            {loading ? (
              <Loader />
            ) : (
              <ListingTable data={paginatedData} refetch={refetch} />
            )}
          </div>
          <div
            className="d-flex mt-2"
            style={{ justifyContent: "space-between" }}
          >
            <div>
              <PerPageDropdown
                onPerPageChange={(perPage) => {
                  setPerPage(perPage);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className="pagination-wrap hstack gap-2">
              {paginatedData?.length > 0 && (
                <Pagination
                  className="pagination"
                  currentPage={currentPage}
                  totalCount={numberOfTotalPages}
                  pageSize={perPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};
