export const NoRecordFoundTable = () => {
  return (
    <div className="noresult">
      <div className="text-center">
        <lord-icon
          src="https://cdn.lordicon.com/msoeawqm.json"
          trigger="loop"
          colors="primary:#121331,secondary:#08a88a"
          style={{ width: "75px", height: "75px" }}
        ></lord-icon>
        <h5 className="mt-2">Sorry! No Result Found</h5>
      </div>
    </div>
  );
};
