import { DEFAULT_PER_PAGE } from "_constants";
import { monthStartEndDates } from "./month-start-end-dates";

export const invoicesFilter = (currentPage, perPage, filters) => {
  let { startDate, endDate } = monthStartEndDates();
  const filtersObj = {
    fields: [],
  };

  if (
    filters &&
    filters.invoiceDate &&
    filters.invoiceDate.start &&
    filters.invoiceDate.end
  ) {
    const { start, end } = filters.invoiceDate;
    startDate = start;
    endDate = end;
  }
  filtersObj.fields.push({
    field: "startDate",
    value: startDate,
    type: "normal",
  });
  filtersObj.fields.push({
    field: "endDate",
    value: endDate,
    type: "normal",
  });

  if (filters && filters.user && filters.user !== "all") {
    filtersObj.fields.push({
      field: "user._id",
      value: filters.user,
      type: "mongo",
    });
  }

  if (filters && filters.currency && filters.currency !== "all") {
    filtersObj.fields.push({
      field: "paidCurrency",
      value: filters.currency.toLowerCase(),
      type: "normal",
    });
  }

  const currentPagePagination = currentPage ? currentPage : 1;
  const perPagePagination = perPage ? perPage : DEFAULT_PER_PAGE;
  const skip = (currentPagePagination - 1) * perPagePagination;
  const take = perPagePagination;
  const orderBy = { field: "createdAt", value: "desc" };
  return {
    filters: filtersObj,
    pagination: { take, skip },
    orderBy,
  };
};
