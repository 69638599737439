import React from "react";
import { useLocation, useNavigate } from "react-router";

//import Scss
import "react-toastify/dist/ReactToastify.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "./assets/scss/themes.scss";

//imoprt Route
import { RouteContainer } from "_routes";

//imoprt Helpers
import { history } from "_helpers";
import { APP_VERSION } from "_constants";

function App() {
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  history.navigate = useNavigate();
  history.location = useLocation();

  const storedVersion = window.localStorage.getItem("appVersion");
  const currentVersion = APP_VERSION;
  if (storedVersion !== currentVersion) {
    // Clear local storage
    window.localStorage.clear();
    window.localStorage.setItem("appVersion", currentVersion);
    window.location.reload();
  }

  return (
    <React.Fragment>
      <RouteContainer />
    </React.Fragment>
  );
}

export { App };
