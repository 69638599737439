import { useQuery } from "@apollo/client";
import { Fragment } from "react";
import { Card } from "reactstrap";
import SimpleBar from "simplebar-react";
import { Loader } from "_components";
import { DASHBOARD_SALES_STATS } from "_queries/Affiliate";
import { queryFilters } from "_utils";
import { Link } from "react-router-dom";

const mockSales = [
  { currency: "usd", totalCommission: 0 },
  { currency: "eur", totalCommission: 0 },
  { currency: "aud", totalCommission: 0 },
  { currency: "gbp", totalCommission: 0 },
  { currency: "cad", totalCommission: 0 },
];

export const SalesGrid = ({ startDate, endDate, title, status, slug }) => {
  const { data, loading } = useQuery(DASHBOARD_SALES_STATS, {
    variables: queryFilters({
      transactionDate: { start: startDate, end: endDate },
      status: status,
    }),
  });
  let sales = data?.dashboardSalesStats;
  if (sales && sales.length === 0) {
    sales = mockSales;
  }

  return (
    <Fragment>
      <Card className="card-body" style={{ height: "300px" }}>
        <h5 className="card-title mb-4">{title}</h5>
        <div className="table-responsive table-card">
          <SimpleBar style={{ maxHeight: "246px" }}>
            {loading ? (
              <Loader />
            ) : (
              <ul className="list-group list-group-flush">
                {sales &&
                  sales.map((item, key) => (
                    <li
                      key={key}
                      className="list-group-item list-group-item-action"
                    >
                      <div className="d-flex align-items-center">
                        <div className="ms-3 flex-grow-1">
                          <h6 className="fs-14 mb-1">
                            {item.currency.toUpperCase()}
                          </h6>
                        </div>
                        <div>
                          <h6>{item.totalCommission.toFixed(2)}</h6>
                        </div>
                      </div>
                    </li>
                  ))}
                {sales && sales.length === 0 && (
                  <li>
                    <div className="mt-2" style={{ textAlign: "center" }}>
                      No data
                    </div>
                  </li>
                )}
              </ul>
            )}
          </SimpleBar>
        </div>
        {slug === "withdraw-now" && (
          <Link to="/affiliate/request-payment" className="btn btn-success">
            Request Withdraw
          </Link>
        )}
      </Card>
    </Fragment>
  );
};
