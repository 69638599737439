import { Fragment } from "react";
import { fullName, limitString } from "_utils";

export const UserInfoInTable = (props) => {
  return (
    <Fragment>
      <span>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <h5 className="fs-14 mb-1">
              {fullName(props.user.firstName, props.user.lastName)} | {""}
              {props.user.referenceId}
            </h5>
            <p className="text-muted mb-0">
              <a
                href={props.user.website}
                target="_blank"
                rel="noreferrer"
                className="text-dark"
              >
                {limitString(props.user.website, 35)}
              </a>
            </p>
          </div>
        </div>
      </span>
    </Fragment>
  );
};
