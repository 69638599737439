import { useQuery } from "@apollo/client";
import { Fragment } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Loader, NoRecordFoundTable } from "_components";
import { GET_ALL_CATEGORIES } from "_queries/Shared";

export const AllCategories = () => {
  const { data, loading } = useQuery(GET_ALL_CATEGORIES);
  const categories = data?.categories;

  return (
    <Fragment>
      <Card>
        <CardHeader className="card-header border-0">
          <div className="d-flex align-items-center">
            <h5 className="card-title mb-0 flex-grow-1">Categories</h5>
            <div className="flex-shrink-0"></div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="table-responsive table-card mb-4">
            {loading ? (
              <Loader />
            ) : (
              <Fragment>
                <table className="table align-middle table-nowrap mb-0">
                  <thead className="table-light text-muted">
                    <tr>
                      <th className="min-w-100px">ID</th>
                      <th className="min-w-100px">Name</th>
                    </tr>
                  </thead>

                  <tbody className="list form-check-all">
                    {(categories || []).map((category) => (
                      <tr key={category.referenceId}>
                        <td>{category.referenceId}</td>
                        <td>{category.categoryName}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {categories && categories.length === 0 && (
                  <NoRecordFoundTable />
                )}
              </Fragment>
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};
