import { useUserInfo } from "_hooks/Shared";
import { Fragment } from "react";
import { Link } from "react-router-dom";

export const ReferenceTransactionsLink = (props) => {
  const { userInfo } = useUserInfo();
  const userRole = userInfo.roles.find(Boolean);

  return (
    <Fragment>
      <Link
        to={`/${userRole}/transactions?invoice_no=${props.invoice.invoiceNo}`}
        className="btn btn-sm btn-secondary"
      >
        Transactions
      </Link>
    </Fragment>
  );
};
