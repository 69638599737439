import {
  Users,
  AllCategories,
  ManualUploads,
  OffersApprovedAndDeclined,
  Invoices as AdminInvoices,
  Dashboard as AdminDashboard,
  AllOffers as AdminAllOffers,
  Transactions as AdminTransactions,
  OffersApply,
  UserSalesReport,
  EditOffer,
} from "containers/Admin";
import {
  AllOffers as AffiliateAllOffers,
  MyOffers as AffiliateMyOffers,
  Dashboard as AffiliateDashboard,
  Transactions as AffiliateTransactions,
  Invoices as AffiliateInvoices,
  Profile,
  RequestPayment,
  PerformanceReport,
  BankDetails,
} from "containers/Affiliate";
import { v4 as uuidv4 } from "uuid";

export const routes = {
  admin: [
    {
      id: uuidv4(),
      label: "Homepage",
      path: "homepage",
      queryParams: "",
      icon: "bxs-dashboard",
      component: (param) => <AdminDashboard {...param} />,
      showInMenu: true,
      children: [],
    },
    {
      id: uuidv4(),
      label: "Advertisers",
      path: "advertisers",
      queryParams: "",
      icon: "bxs-dashboard",
      component: (param) => <AdminAllOffers {...param} />,
      showInMenu: true,
      children: [
        {
          id: uuidv4(),
          label: "New Advertisers",
          path: "new-advertisers",
          queryParams: "",
          component: (param) => <AdminAllOffers {...param} />,
          showInMenu: true,
        },
        {
          id: uuidv4(),
          label: "Modify Advertisers",
          path: "new-advertisers/:id/edit",
          queryParams: "",
          component: (param) => <EditOffer {...param} />,
          showInMenu: false,
        },
        {
          id: uuidv4(),
          label: "Apply Advertisers",
          path: "offers-apply",
          queryParams: "",
          component: (param) => <OffersApply {...param} />,
          showInMenu: true,
        },
        {
          id: uuidv4(),
          label: "Approved/Declined Advertisers",
          path: "offers-approved-declined",
          queryParams: "",
          component: (param) => <OffersApprovedAndDeclined {...param} />,
          showInMenu: true,
        },
      ],
    },
    {
      id: uuidv4(),
      label: "Users",
      path: "users",
      queryParams: "",
      icon: "bxs-dashboard",
      component: (param) => <Users {...param} />,
      showInMenu: true,
      children: [],
    },
    {
      id: uuidv4(),
      label: "Transaction Details",
      path: "transactions",
      queryParams: "",
      icon: "bxs-dashboard",
      component: (param) => <AdminTransactions {...param} />,
      showInMenu: true,
      children: [],
    },
    {
      id: uuidv4(),
      label: "Invoices",
      path: "invoices",
      queryParams: "",
      icon: "bxs-dashboard",
      component: (param) => <AdminInvoices {...param} />,
      showInMenu: true,
      children: [],
    },
    {
      id: uuidv4(),
      label: "Reports",
      path: "reports",
      queryParams: "",
      icon: "bxs-dashboard",
      component: (param) => <UserSalesReport {...param} />,
      showInMenu: true,
      children: [
        {
          id: uuidv4(),
          label: "User Sales Report",
          path: "user-sales-report",
          queryParams: "",
          component: (param) => <UserSalesReport {...param} />,
          showInMenu: true,
        },
      ],
    },
    {
      id: uuidv4(),
      label: "Uploads",
      path: "manual-uploads",
      queryParams: "",
      icon: "bxs-dashboard",
      component: (param) => <ManualUploads {...param} />,
      showInMenu: true,
      children: [],
    },
    {
      id: uuidv4(),
      label: "Categories",
      path: "categories",
      queryParams: "",
      icon: "bxs-dashboard",
      component: (param) => <AllCategories {...param} />,
      showInMenu: true,
      children: [],
    },
  ],
  editor: [
    {
      id: uuidv4(),
      label: "Advertisers",
      path: "advertisers",
      queryParams: "",
      icon: "bxs-dashboard",
      component: (param) => <AdminAllOffers {...param} />,
      showInMenu: true,
      children: [
        {
          id: uuidv4(),
          label: "New Advertisers",
          path: "new-advertisers",
          queryParams: "",
          component: (param) => <AdminAllOffers {...param} />,
          showInMenu: true,
        },
        {
          id: uuidv4(),
          label: "Modify Advertisers",
          path: "new-advertisers/:id/edit",
          queryParams: "",
          component: (param) => <EditOffer {...param} />,
          showInMenu: false,
        },
      ],
    },
    {
      id: uuidv4(),
      label: "Categories",
      path: "categories",
      queryParams: "",
      icon: "bxs-dashboard",
      component: (param) => <AllCategories {...param} />,
      showInMenu: true,
      children: [],
    },
  ],
  affiliate: [
    {
      id: uuidv4(),
      label: "Homepage",
      path: "homepage",
      queryParams: "",
      icon: "bxs-dashboard",
      component: (param) => <AffiliateDashboard {...param} />,
      showInMenu: true,
      children: [],
    },
    {
      id: uuidv4(),
      label: "Advertisers",
      path: "advertisers",
      queryParams: "?status=approved",
      icon: "bxs-dashboard",
      component: (param) => <AffiliateAllOffers {...param} />,
      showInMenu: true,
      children: [
        {
          id: uuidv4(),
          label: "My Advertisers",
          path: "my-advertisers",
          queryParams: "?status=approved",
          icon: "bxs-dashboard",
          component: (param) => <AffiliateMyOffers {...param} />,
          showInMenu: true,
        },
        {
          id: uuidv4(),
          label: "New Advertisers",
          path: "new-advertisers",
          queryParams: "",
          icon: "bxs-dashboard",
          component: (param) => <AffiliateAllOffers {...param} />,
          showInMenu: true,
        },
        {
          id: uuidv4(),
          label: "Pending Advertisers",
          path: "my-advertisers",
          queryParams: "?status=pending",
          icon: "bxs-dashboard",
          component: (param) => <AffiliateMyOffers {...param} />,
          showInMenu: true,
        },
        {
          id: uuidv4(),
          label: "Declined Advertisers",
          path: "my-advertisers",
          queryParams: "?status=declined",
          icon: "bxs-dashboard",
          component: (param) => <AffiliateMyOffers {...param} />,
          showInMenu: true,
        },
      ],
    },
    {
      id: uuidv4(),
      label: "Transaction Details",
      path: "transactions",
      queryParams: "",
      icon: "bxs-dashboard",
      component: (param) => <AffiliateTransactions {...param} />,
      showInMenu: true,
      children: [],
    },
    {
      id: uuidv4(),
      label: "Payments",
      path: "payments",
      queryParams: "",
      icon: "bxs-dashboard",
      component: (param) => <RequestPayment {...param} />,
      showInMenu: true,
      children: [
        {
          id: uuidv4(),
          label: "Invoices",
          path: "invoices",
          queryParams: "",
          icon: "bxs-dashboard",
          component: (param) => <AffiliateInvoices {...param} />,
          showInMenu: true,
        },
        {
          id: uuidv4(),
          label: "Request Payment",
          path: "request-payment",
          queryParams: "",
          icon: "bxs-dashboard",
          component: (param) => <RequestPayment {...param} />,
          showInMenu: true,
        },
      ],
    },
    {
      id: uuidv4(),
      label: "Reports",
      path: "reports",
      queryParams: "",
      icon: "bxs-dashboard",
      component: (param) => <PerformanceReport {...param} />,
      showInMenu: true,
      children: [
        {
          id: uuidv4(),
          label: "Performance Report",
          path: "performance-report",
          queryParams: "",
          component: (param) => <PerformanceReport {...param} />,
          showInMenu: true,
        },
      ],
    },
    {
      id: uuidv4(),
      label: "Bank Details",
      path: "bank-details",
      queryParams: "",
      icon: "bxs-dashboard",
      component: (param) => <BankDetails {...param} />,
      showInMenu: true,
      children: [],
    },
    {
      id: uuidv4(),
      label: "Profile",
      path: "profile",
      queryParams: "",
      icon: "bxs-dashboard",
      component: (param) => <Profile {...param} />,
      showInMenu: false,
      children: [],
    },
  ],
};
