import { DEFAULT_PER_PAGE } from "_constants";

export const offersFilter = (currentPage, perPage, filters) => {
  const filtersObj = {
    fields: [],
  };

  if (filters && filters.category && filters.category !== "all") {
    filtersObj.fields.push({
      field: "category._id",
      value: filters.category,
      type: "mongo",
    });
  }
  if (filters && filters.offer && filters.offer !== "all") {
    filtersObj.fields.push({
      field: "_id",
      value: filters.offer,
      type: "mongo",
    });
  }
  if (filters && filters.country && filters.country !== "all") {
    filtersObj.fields.push({
      field: "country",
      value: filters.country,
      type: "normal",
    });
  }
  if (filters && filters.platform && filters.platform !== "all") {
    filtersObj.fields.push({
      field: "platform",
      value: filters.platform.toLowerCase(),
      type: "normal",
    });
  }
  if (filters && filters.status) {
    filtersObj.fields.push({
      field: "status",
      value: filters.status,
      type: "normal",
    });
  }
  if (filters && filters.searchTerm) {
    filtersObj.fields.push({
      field: "term",
      value: filters.searchTerm,
      type: "normal",
    });
  }

  // const skip = (currentPage - 1) * perPage;
  // const take = perPage;
  const currentPagePagination = currentPage ? currentPage : 1;
  const perPagePagination = perPage ? perPage : DEFAULT_PER_PAGE;
  const skip = (currentPagePagination - 1) * perPagePagination;
  const take = perPagePagination;
  const orderBy = { field: "commission", value: "desc" };
  return {
    filters: filtersObj,
    pagination: { take, skip },
    orderBy,
  };
};
