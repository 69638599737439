import React, { Fragment, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_PER_PAGE } from "_constants";
import { Filters } from "./Filters";
import { Listing } from "./Listing";
import { useQuery } from "@apollo/client";
import { offersFilter } from "_utils";
import { GET_MY_OFFERS } from "_queries/Affiliate";

export const MyOffers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [filters, setFilters] = useState(null);
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  const { data, loading: loadingOffers } = useQuery(GET_MY_OFFERS, {
    variables: offersFilter(currentPage, perPage, filters),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    //refetch();
    handleFilterChange("status", status);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const paginatedData = data?.appliedOffers?.offers;
  const numberOfTotalPages = data?.appliedOffers.count;

  const handleFilterChange = (key, value) => {
    setFilters(() => {
      return {
        ...filters,
        [key]: value,
      };
    });
  };

  const filtersProps = {
    setCurrentPage,
    handleFilterChange,
  };

  const listingProps = {
    numberOfTotalPages,
    paginatedData,
    setCurrentPage,
    setPerPage,
    currentPage,
    perPage,
    loadingOffers,
    status,
  };
  return (
    <Fragment>
      <Row id="filter">
        <Col lg={12}>
          <Filters {...filtersProps} />
        </Col>
      </Row>
      <Row id="listing">
        <Col lg={12}>
          <Listing {...listingProps} />
        </Col>
      </Row>
    </Fragment>
  );
};
