import {
  CurrenciesDropdown,
  DownloadTransactionsButton,
  OffersDropdown,
  PlatformDropdown,
  TransactionStatusDropdown,
  UsersDropdown,
} from "_components";
import { Fragment } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Card, CardBody, CardHeader } from "reactstrap";
import moment from "moment/moment";
import { TransactionStatus } from "_constants";
import { firstLetterCapital } from "_utils";

const transactionStatuses = [{ label: "All", value: "all" }];
for (const key in TransactionStatus) {
  if (TransactionStatus.hasOwnProperty(key)) {
    transactionStatuses.push({ label: firstLetterCapital(key), value: key });
  }
}

export const Filters = ({ handleFilterChange, setCurrentPage, filters }) => {
  const handleEventTransactionDate = (event, picker) => {
    const { startDate, endDate } = picker;
    const start = moment(startDate).format("Y-MM-DD");
    const end = moment(endDate).format("Y-MM-DD");
    handleFilterChange("transactionDate", {
      start,
      end,
    });
    setCurrentPage(1);
  };

  return (
    <Fragment>
      <Card>
        <CardHeader className="card-header border-0">
          <div className="d-flex align-items-center">
            <h5 className="card-title mb-0 flex-grow-1">Filters</h5>
            <div className="flex-shrink-0">
              <DownloadTransactionsButton filters={filters} />
            </div>
          </div>
        </CardHeader>
        <CardBody className="border">
          <div className="row g-3">
            <div className="col-xxl-3 col-sm-12">
              <DateRangePicker
                initialSettings={{
                  startDate: moment().startOf("month"),
                  endDate: moment().endOf("month"),
                }}
                onApply={handleEventTransactionDate}
              >
                <input type="text" className="form-control form-custom" />
              </DateRangePicker>
            </div>
            <div className="col-xxl-3 col-sm-4">
              <OffersDropdown
                onOfferChange={(offer) => {
                  handleFilterChange("offer", offer);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className="col-xxl-3 col-sm-4">
              <UsersDropdown
                orderByKey={`firstName`}
                orderByValue={`asc`}
                onUserChange={(user) => {
                  handleFilterChange("user", user);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className="col-xxl-3 col-sm-4">
              <CurrenciesDropdown
                onCurrencyChange={(currency) => {
                  handleFilterChange("currency", currency);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className="col-xxl-3 col-sm-4">
              <PlatformDropdown
                onPlatformChange={(platform) => {
                  handleFilterChange("platform", platform);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className="col-xxl-3 col-sm-4">
              <TransactionStatusDropdown
                statuses={transactionStatuses}
                onTransactionStatusChange={(status) => {
                  handleFilterChange("status", status);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className="col-xxl-2 col-sm-4">
              <button
                type="button"
                className="btn btn-danger w-50"
                onClick={() => window.location.reload(false)}
              >
                <i className="ri-equalizer-fill me-1 align-bottom"></i>
                Reset
              </button>
            </div>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};
