import Select from "react-select";

export const PerPageDropdown = (props) => {
  const { onPerPageChange } = props;

  const options = [
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
  ];
  return (
    <Select
      className="input-sm"
      size={3}
      isClearable={false}
      defaultValue={[options[0]]}
      options={options}
      onChange={(e) => onPerPageChange(parseInt(e.value))}
    />
  );
};
