import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { REGISTER_MUTATION } from "_queries/Shared";
import { transformGQLErrors } from "_utils";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import countries from "_data/countries";
import { Fragment } from "react";
import { ParticlesAuth } from "_components";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Row,
} from "reactstrap";
import { APP_NAME } from "_constants";

const AWS_S3_BUCKET_URL = process.env.REACT_APP_AWS_S3_BUCKET_URL;

const schema = yup
  .object({
    firstName: yup.string().required("First name is required."),
    lastName: yup.string().required("Last name is required."),
    email: yup.string().email().required("Email is required."),
    phone: yup.string().required("Phone is required."),
    password: yup.string().required("Password is required."),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    address: yup.string().required("Address is required."),
    state: yup.string().required("State is required."),
    city: yup.string().required("City is required."),
    zipcode: yup.string().required("Zip code is required."),
    website: yup.string().url().required("Website is required."),
    skypeId: yup.string().required("Skype ID is required."),
  })
  .required();

export const Register = () => {
  const [registerUser, { loading }] = useMutation(REGISTER_MUTATION, {
    errorPolicy: "all",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const websiteTypes = [
    "Blog",
    "Network",
    "Community",
    "Forum",
    "Cashback or Loyalty",
    "Parked Domain",
    "Coupon or Rebate",
    "Search Engine",
    "SEM",
    "Other",
  ];

  const onSubmit = async (data) => {
    const response = await registerUser({
      variables: data,
    });

    if (response) {
      const userId =
        response &&
        response.data &&
        response.data.createUser &&
        response.data.createUser._id;

      if (userId) {
        reset();
        toast.success(
          "Account has been created and waiting for ADMIN approval."
        );
      }
    }

    //Show errors if any
    transformGQLErrors(response).map((e) => toast.error(e));
  };

  return (
    <Fragment>
      <div className="auth-page-wrapper pt-5">
        <ParticlesAuth>
          <div className="auth-page-content">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="text-center mt-sm-5 mb-4 text-white-50">
                    <div>
                      <Link to="/" className="d-inline-block auth-logo">
                        <img
                          src={`${AWS_S3_BUCKET_URL}/app-logos/logo-light.png`}
                          alt=""
                          height="100"
                        />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={8}>
                  <Card className="mt-4">
                    <CardBody className="p-4">
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="text-center mt-2">
                          <h5 className="text-primary">Create New Account</h5>
                          <p className="text-muted">
                            Get your free {APP_NAME} account now
                          </p>
                        </div>

                        <Row className="gy-4">
                          <Col xxl={6} md={6}>
                            <div>
                              <Label className="form-label">First Name</Label>
                              <input
                                type="text"
                                placeholder="First Name"
                                {...register("firstName")}
                                className={`form-control  ${
                                  errors.firstName ? "is-invalid" : ""
                                }`}
                              />
                              <div className="invalid-feedback">
                                {errors.firstName?.message}
                              </div>
                            </div>
                          </Col>
                          <Col xxl={6} md={6}>
                            <div>
                              <Label className="form-label">Last name</Label>
                              <input
                                type="text"
                                placeholder="Last Name"
                                {...register("lastName")}
                                className={`form-control  ${
                                  errors.lastName ? "is-invalid" : ""
                                }`}
                              />
                              <div className="invalid-feedback">
                                {errors.lastName?.message}
                              </div>
                            </div>
                          </Col>
                          <Col xxl={6} md={6}>
                            <div>
                              <Label className="form-label">Email</Label>
                              <input
                                type="email"
                                placeholder="Email"
                                {...register("email")}
                                className={`form-control  ${
                                  errors.email ? "is-invalid" : ""
                                }`}
                              />
                              <div className="invalid-feedback">
                                {errors.email?.message}
                              </div>
                            </div>
                          </Col>
                          <Col xxl={6} md={6}>
                            <div>
                              <Label className="form-label">Password</Label>
                              <input
                                type="password"
                                placeholder="Password"
                                {...register("password")}
                                className={`form-control  ${
                                  errors.password ? "is-invalid" : ""
                                }`}
                              />
                              <div className="invalid-feedback">
                                {errors.password?.message}
                              </div>
                            </div>
                          </Col>
                          <Col xxl={6} md={6}>
                            <div>
                              <Label className="form-label">
                                Confirm Password
                              </Label>
                              <input
                                type="password"
                                placeholder="Confirm Password"
                                {...register("confirmPassword")}
                                className={`form-control  ${
                                  errors.confirmPassword ? "is-invalid" : ""
                                }`}
                              />
                              <div className="invalid-feedback">
                                {errors.confirmPassword?.message}
                              </div>
                            </div>
                          </Col>
                          <Col xxl={6} md={6}>
                            <div>
                              <Label className="form-label">Phone</Label>
                              <input
                                type="text"
                                placeholder="Phone"
                                {...register("phone")}
                                className={`form-control  ${
                                  errors.phone ? "is-invalid" : ""
                                }`}
                              />
                              <div className="invalid-feedback">
                                {errors.phone?.message}
                              </div>
                            </div>
                          </Col>
                          <Col xxl={6} md={6}>
                            <div>
                              <Label className="form-label">Country</Label>
                              <select
                                {...register("country")}
                                className={`form-control form-select ${
                                  errors.country ? "is-invalid" : ""
                                }`}
                              >
                                {countries.map((country, index) => {
                                  return (
                                    <option value={country.name} key={index}>
                                      {country.name}
                                    </option>
                                  );
                                })}
                              </select>
                              <div className="invalid-feedback">
                                {errors.country?.message}
                              </div>
                            </div>
                          </Col>
                          <Col xxl={6} md={6}>
                            <div>
                              <Label className="form-label">State</Label>
                              <input
                                type="text"
                                placeholder="State"
                                {...register("state")}
                                className={`form-control  ${
                                  errors.state ? "is-invalid" : ""
                                }`}
                              />
                              <div className="invalid-feedback">
                                {errors.state?.message}
                              </div>
                            </div>
                          </Col>
                          <Col xxl={6} md={6}>
                            <div>
                              <Label className="form-label">City</Label>
                              <input
                                type="text"
                                placeholder="City"
                                {...register("city")}
                                className={`form-control  ${
                                  errors.city ? "is-invalid" : ""
                                }`}
                              />
                              <div className="invalid-feedback">
                                {errors.city?.message}
                              </div>
                            </div>
                          </Col>
                          <Col xxl={6} md={6}>
                            <div>
                              <Label className="form-label">Zip code</Label>
                              <input
                                type="text"
                                placeholder="Zip code"
                                {...register("zipcode")}
                                className={`form-control  ${
                                  errors.zipcode ? "is-invalid" : ""
                                }`}
                              />
                              <div className="invalid-feedback">
                                {errors.zipcode?.message}
                              </div>
                            </div>
                          </Col>
                          <Col xxl={6} md={6}>
                            <div>
                              <Label className="form-label">Address</Label>
                              <input
                                type="text"
                                placeholder="Address"
                                {...register("address")}
                                className={`form-control  ${
                                  errors.address ? "is-invalid" : ""
                                }`}
                              />
                              <div className="invalid-feedback">
                                {errors.address?.message}
                              </div>
                            </div>
                          </Col>
                          <Col xxl={6} md={6}>
                            <div>
                              <Label className="form-label">Website</Label>
                              <input
                                type="website"
                                placeholder="Website"
                                {...register("website")}
                                className={`form-control  ${
                                  errors.website ? "is-invalid" : ""
                                }`}
                              />
                              <small>https://www.google.com</small>
                              <div className="invalid-feedback">
                                {errors.website?.message}
                              </div>
                            </div>
                          </Col>
                          <Col xxl={6} md={6}>
                            <div>
                              <Label className="form-label">Website Type</Label>
                              <select
                                {...register("websiteType")}
                                className={`form-control form-select ${
                                  errors.websiteType ? "is-invalid" : ""
                                }`}
                              >
                                {(websiteTypes || []).map(
                                  (websiteType, index) => {
                                    return (
                                      <option value={websiteType} key={index}>
                                        {websiteType}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                              <div className="invalid-feedback">
                                {errors.websiteType?.message}
                              </div>
                            </div>
                          </Col>
                          <Col xxl={6} md={6}>
                            <div>
                              <Label className="form-label">Skype ID</Label>
                              <input
                                type="text"
                                placeholder="Skype ID"
                                {...register("skypeId")}
                                className={`form-control  ${
                                  errors.skypeId ? "is-invalid" : ""
                                }`}
                              />
                              <div className="invalid-feedback">
                                {errors.skypeId?.message}
                              </div>
                            </div>
                          </Col>
                          <p className="mb-0 fs-12 text-muted fst-italic">
                            {`By registering you agree to the ${APP_NAME} `}
                            <Link
                              to="#"
                              className="text-primary text-decoration-underline fst-normal fw-medium"
                            >
                              Terms of Use
                            </Link>
                          </p>
                        </Row>

                        <div className="mt-4">
                          <Button
                            color="secondary"
                            className="btn btn-primary w-100"
                            disabled={loading}
                          >
                            Sign Up
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>

                  <div className="mt-4 text-center">
                    <p className="mb-0">
                      {`Already have an account ? `}
                      <Link
                        className="fw-semibold text-primary text-decoration-underline"
                        to={`/login`}
                      >
                        Signin
                      </Link>
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </ParticlesAuth>
      </div>
    </Fragment>
  );
};
