import { UsersDropdown } from "_components";
import { Fragment } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

export const Filters = ({ handleFilterChange, setCurrentPage }) => {
  return (
    <Fragment>
      <Card>
        <CardHeader className="card-header border-0">
          <div className="d-flex align-items-center">
            <h5 className="card-title mb-0 flex-grow-1">Filters</h5>
            <div className="flex-shrink-0"></div>
          </div>
        </CardHeader>
        <CardBody className="border">
          <div className="row g-3">
            <div className="col-xxl-3 col-sm-4">
              <UsersDropdown
                orderByKey={`firstName`}
                orderByValue={`asc`}
                onUserChange={(user) => {
                  handleFilterChange("user", user);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className="col-xxl-2 col-sm-4">
              <button
                type="button"
                className="btn btn-danger w-50"
                onClick={() => window.location.reload(false)}
              >
                <i className="ri-equalizer-fill me-1 align-bottom"></i>
                Reset
              </button>
            </div>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};
