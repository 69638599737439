import { Fragment, useEffect } from "react";
import { firstLetterCapital, timestampToDate } from "_utils";
import {
  ListingCheckboxSingle,
  ListingCheckboxAll,
  NoRecordFoundTable,
  TransactionStatus,
  UserInfoInTable,
  OfferInfoInTable,
} from "_components";
import { invoiceNumber } from "_utils/invoice-number";

const columns = [
  { label: "Date", class: "min-w-100px" },
  { label: "Transaction ID", class: "min-w-200px" },
  { label: "ref1", class: "min-w-150px" },
  { label: "ref2", class: "min-w-150px" },
  { label: "Advertiser", class: "min-w-150px" },
  { label: "User", class: "min-w-150px" },
  { label: "Platform", class: "min-w-50px" },
  { label: "Currency", class: "min-w-50px" },
  { label: "Sale Amount", class: "min-w-50px" },
  { label: "Commission", class: "min-w-50px" },
  { label: "Payable %", class: "min-w-50px" },
  { label: "Payable", class: "min-w-50px" },
  { label: "Profit", class: "min-w-50px" },
  { label: "Invoice No", class: "min-w-50px" },
  { label: "Status", class: "min-w-50px" },
];

export const ListingTable = ({
  data,
  selectedTransactions,
  outputTransactionsSelect,
}) => {
  useEffect(() => {
    //uncheck all the checkboxes
    if (selectedTransactions.length === 0) {
      document.getElementById("checkBoxAll").checked = false;
      document
        .querySelectorAll(".taskCheckBox")
        .forEach((el) => (el.checked = false));
    }
  }, [selectedTransactions]);

  return (
    <Fragment>
      <table className="table align-middle table-nowrap mb-0">
        <thead className="table-light text-muted">
          <tr>
            <th>
              <ListingCheckboxAll
                onCheckboxAllChange={outputTransactionsSelect}
              />
            </th>
            {columns.map((column, index) => (
              <th className={column.class} key={index}>
                {column.label}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="list form-check-all">
          {data &&
            data.map((transaction) => (
              <tr key={transaction._id}>
                <td>
                  <ListingCheckboxSingle
                    value={transaction._id}
                    onCheckboxChange={outputTransactionsSelect}
                  />
                </td>
                <td>{timestampToDate(transaction.transactionDate)}</td>
                <td>{transaction.referenceId}</td>
                <td>{transaction.reference.ref1}</td>
                <td>{transaction.reference.ref2}</td>
                <td>
                  <OfferInfoInTable offer={transaction.offer} />
                </td>
                <td>
                  <UserInfoInTable user={transaction.user} />
                </td>
                <td>{firstLetterCapital(transaction.platform)}</td>
                <td>{transaction.currency?.toUpperCase()}</td>
                <td>{transaction.saleAmount.toFixed(2)}</td>
                <td>{transaction.commission.toFixed(2)}</td>
                <td>{transaction.commissionPercentage}%</td>
                <td>{transaction.payable.toFixed(2)}</td>
                <td>{transaction.profit.toFixed(2)}</td>
                <td>
                  {transaction.invoice &&
                    invoiceNumber(transaction.invoice?.invoiceNo)}
                </td>
                <td>
                  <TransactionStatus status={transaction.status} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {data && data.length === 0 && <NoRecordFoundTable />}
    </Fragment>
  );
};
