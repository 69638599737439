import { useMutation } from "@apollo/client";
import { APPLY_OFFER } from "_queries/Affiliate";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";

export const ApplyOfferButton = ({
  offer,
  refetch,
  loadingOffers,
  loadingApplyOffers,
}) => {
  const [loading, setLoading] = useState(false);
  const [applyOffer] = useMutation(APPLY_OFFER, {
    errorPolicy: "all",
  });

  const handleApplyBtn = async () => {
    setLoading(true);
    const response = await applyOffer({
      variables: { offerId: offer._id },
    });
    if (response) {
      toast.success(`Advertiser has been applied.`);
      refetch();
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {!loading ? (
        <button
          className="btn btn-secondary"
          onClick={handleApplyBtn}
          disabled={loadingOffers || loadingApplyOffers}
        >
          Apply
        </button>
      ) : (
        <div className="spinner-border spinner-action text-success">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </Fragment>
  );
};
