import { useQuery } from "@apollo/client";
import { Fragment, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Row,
  Table,
} from "reactstrap";
import { USER_SALES_REPORT } from "_queries/Admin";
import { transactionsFilter } from "_utils";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment/moment";
import { Loader, UserInfoInTable } from "_components";
import { useLogin } from "_hooks/Shared";
import { DownloadUserSalesReportButton } from "_components/DownloadUserSalesReportButton";

export const UserSalesReport = () => {
  const [filters, setFilters] = useState(null);
  const [collapsedRows, setCollapsedRows] = useState({ 0: true, 1: true });
  const loginAndNavigate = useLogin();

  const { data: dataUserSalesReport, loading } = useQuery(USER_SALES_REPORT, {
    variables: transactionsFilter(null, null, filters),
    fetchPolicy: "no-cache",
  });

  const toggleRow = (id) => {
    setCollapsedRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleFilterChange = (key, value) => {
    setFilters(() => {
      return {
        ...filters,
        [key]: value,
      };
    });
  };

  const handleEventFilterDate = (event, picker) => {
    const { startDate, endDate } = picker;
    const start = moment(startDate).format("Y-MM-DD");
    const end = moment(endDate).format("Y-MM-DD");
    handleFilterChange("transactionDate", {
      start,
      end,
    });
  };

  const handleLoginButton = (id) => {
    loginAndNavigate(id);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader className="card-header border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">
                  User Sales Report
                </h5>
                <div className="flex-shrink-0">
                  <DownloadUserSalesReportButton filters={filters} />
                </div>
              </div>
            </CardHeader>
            <CardBody className="border border-dashed border-end-0 border-start-0">
              <form>
                <div className="row g-3">
                  <div className="col-xxl-4 col-sm-12">
                    <DateRangePicker
                      initialSettings={{
                        startDate: moment().startOf("month"),
                        endDate: moment().endOf("month"),
                      }}
                      onApply={handleEventFilterDate}
                    >
                      <input type="text" className="form-control form-custom" />
                    </DateRangePicker>
                  </div>

                  <div className="col-xxl-2 col-sm-4">
                    <button type="button" className="btn btn-danger w-100">
                      <i className="ri-equalizer-fill me-1 align-bottom"></i>
                      Reset Filters
                    </button>
                  </div>
                </div>
              </form>
            </CardBody>
            <CardBody>
              <div className="table-responsive table-card mb-4 table-transaction">
                {loading ? (
                  <Loader />
                ) : (
                  <Fragment>
                    <table className="table align-middle table-nowrap mb-0">
                      <tbody className="list form-check-all">
                        {dataUserSalesReport &&
                          dataUserSalesReport.userSalesReportAdmin.map(
                            (parentRow, index) => (
                              <Fragment key={index}>
                                <tr
                                  onClick={() => toggleRow(index)}
                                  key={index}
                                >
                                  <td>
                                    <UserInfoInTable user={parentRow.user} />
                                  </td>
                                  <td align="right">
                                    <button
                                      className="btn btn-secondary w-xs"
                                      onClick={() =>
                                        handleLoginButton(parentRow.user._id)
                                      }
                                    >
                                      <i className="ri-shield-user-line align-bottom me-2"></i>
                                      Login
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2">
                                    <Collapse isOpen={collapsedRows[index]}>
                                      <Table>
                                        <thead className="table-light text-muted">
                                          <tr>
                                            <th>Currency</th>
                                            <th>Orders</th>
                                            <th>Sale Amount</th>
                                            <th>Commission</th>
                                            <th>Payable</th>
                                            <th>Profit</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {parentRow.data.map(
                                            (childRow, index) => (
                                              <tr key={index}>
                                                <td>
                                                  {childRow.currency.toUpperCase()}
                                                </td>
                                                <td>{childRow.totalSales}</td>
                                                <td>
                                                  {childRow.totalSaleAmount.toFixed(
                                                    2
                                                  )}
                                                </td>
                                                <td>
                                                  {childRow.totalCommission.toFixed(
                                                    2
                                                  )}
                                                </td>
                                                <td>
                                                  {childRow.totalPayable.toFixed(
                                                    2
                                                  )}
                                                </td>
                                                <td>
                                                  {childRow.totalProfit.toFixed(
                                                    2
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </Table>
                                    </Collapse>
                                  </td>
                                </tr>
                              </Fragment>
                            )
                          )}
                      </tbody>
                    </table>
                  </Fragment>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
