import React, { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  ProtectedAdminLayout,
  ProtectedAffiliateLayout,
  ProtectedEditorLayout,
  routes,
} from "_routes";
import { Login } from "containers/Login";
import { Register } from "containers/Register";
import { ForgotPassword } from "containers/ForgotPassword";
import { ResetPassword } from "containers/ResetPassword";

export const RouteContainer = () => {
  return (
    <Fragment>
      <Routes>
        <Route path="/admin" element={<ProtectedAdminLayout />}>
          {routes.admin.map((route) => {
            const linerRoute = [route, ...route.children];
            return linerRoute.map((child) => {
              return (
                <Route
                  routeParams={child}
                  key={child.id}
                  path={child.path}
                  element={child.component()}
                />
              );
            });
          })}
        </Route>
        <Route path="/editor" element={<ProtectedEditorLayout />}>
          {routes.editor.map((route) => {
            const linerRoute = [route, ...route.children];
            return linerRoute.map((child) => {
              return (
                <Route
                  routeParams={child}
                  key={child.id}
                  path={child.path}
                  element={child.component()}
                />
              );
            });
          })}
        </Route>
        <Route path="/affiliate" element={<ProtectedAffiliateLayout />}>
          {routes.affiliate.map((route) => {
            const linerRoute = [route, ...route.children];
            return linerRoute.map((child) => {
              return (
                <Route
                  routeParams={child}
                  key={child.id}
                  path={child.path}
                  element={child.component()}
                />
              );
            });
          })}
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Fragment>
  );
};
