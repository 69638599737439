import Select from "react-select";
import { transformDataIntoValueLabel } from "_utils";

export const OfferPerformanceDropdown = (props) => {
  const { onOfferPerformanceChange } = props;
  const statuses = [
    { title: "All", value: "all" },
    { title: "Good", value: "good" },
    { title: "Average", value: "average" },
  ];

  const handleChange = (inputValue) => {
    const val = inputValue ? inputValue.value : "all";
    onOfferPerformanceChange(val);
  };

  return (
    <Select
      placeholder={`Select Performance`}
      isClearable={true}
      defaultOptions={false}
      options={transformDataIntoValueLabel(statuses, "title", "value")}
      onChange={handleChange}
    />
  );
};
