import { useApolloClient } from "@apollo/client";
import { history } from "_helpers";
import {
  removeFromLocalStorage as removeAuthFromLocalStorage,
  useAuthToken,
} from "./useAuthToken";
import { removeUserFromLocalStorage, useUserInfo } from "./useUserInfo";

export const useLogout = () => {
  const { removeAuthToken } = useAuthToken();
  const { removeUserInfo } = useUserInfo();
  const apolloClient = useApolloClient();

  const logout = async () => {
    await apolloClient.clearStore(); // we remove all information in the store
    removeAuthToken();
    removeUserInfo();
    history.navigate("/login");
  };
  return logout;
};

export const logoutWithoutHook = () => {
  removeAuthFromLocalStorage();
  removeUserFromLocalStorage();
  // eslint-disable-next-line no-restricted-globals
  location.reload();
};
