import { Loader } from "_components";
import { Fragment } from "react";
import ReactApexChart from "react-apexcharts";
import { getChartColorsArray } from "_utils";

const mockSales = [
  { currency: "usd", totalCommission: 0.1 },
  { currency: "eur", totalCommission: 0 },
  { currency: "aud", totalCommission: 0 },
  { currency: "gbp", totalCommission: 0 },
  { currency: "cad", totalCommission: 0 },
];

export const PieChartAllCurrencySales = ({
  loadingAllCurrencyData,
  allCurrencyData,
}) => {
  const cloneCurrencyData = [...allCurrencyData];
  cloneCurrencyData.sort(
    (a, b) => parseFloat(b.totalProfit) - parseFloat(a.totalProfit)
  );
  //let currencyData = cloneCurrencyData.slice(0, 3);
  let currencyData = cloneCurrencyData;
  if (currencyData && currencyData.length === 0) {
    currencyData = mockSales;
  }
  return (
    <Fragment>
      <div className="card card-height-100">
        {loadingAllCurrencyData ? (
          <Loader />
        ) : (
          <div className="apex-charts mt-5" dir="ltr">
            <SalesCharts
              chartData={currencyData}
              dataColors='["--vz-primary", "--vz-secondary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]'
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

const SalesCharts = ({ dataColors, chartData }) => {
  const chartDonutBasicColors = getChartColorsArray(dataColors);
  const series = chartData.map((x) => +x.totalCommission.toFixed(2));
  const labels = chartData.map((x) => x.currency.toUpperCase());
  var options = {
    labels,
    chart: {
      height: 333,
      type: "donut",
    },
    legend: {
      position: "bottom",
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    colors: chartDonutBasicColors,
  };

  return (
    <Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height="333"
        className="apex-charts"
      />
    </Fragment>
  );
};
