import { Loader } from "_components";
import { Fragment } from "react";
import CountUp from "react-countup";
import { Card, CardBody, Col } from "reactstrap";

export const StatWidgets = ({ data, loading, blocks }) => {
  const columnSize = () => {
    if (blocks.length === 4) {
      return 3;
    } else if (blocks.length === 3) {
      return 4;
    } else if (blocks.length === 2) {
      return 6;
    } else {
      return 3;
    }
  };

  return (
    <Fragment>
      {blocks.map((item) => (
        <Col xxl={columnSize()} key={item.id}>
          <Card className="card-animate">
            <CardBody>
              {loading ? (
                <Loader />
              ) : (
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <h6 className="text-muted mb-3">{item.label}</h6>
                    <h2 className="mb-0">
                      <span className="counter-value">
                        <CountUp
                          start={0}
                          end={data[item.key]}
                          separator={`,`}
                          duration={3}
                          decimals={item.decimals}
                          prefix={item.prefix}
                        />
                      </span>
                    </h2>
                  </div>
                  <div className="flex-shrink-0 avatar-sm">
                    <div className="avatar-title fs-4 rounded-circle">
                      <i className={item.icon}></i>
                    </div>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      ))}
    </Fragment>
  );
};
