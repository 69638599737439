import { useMutation } from "@apollo/client";
import { DELETE_OFFER } from "_queries/Admin";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

export const DeleteOfferButton = ({ offer, refetch }) => {
  const [loading, setLoading] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);
  const [deleteOffer] = useMutation(DELETE_OFFER, {
    errorPolicy: "all",
  });

  const handleDeleteBtn = async (offerId) => {
    setLoading(true);
    const response = await deleteOffer({
      variables: { id: offerId },
    });
    if (response) {
      toast.success(`Advertiser has been deleted successfully.`);
      refetch();
      setLoading(false);
      setDeleteModal(!deletemodal);
    }
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deletemodal);
  };

  return (
    <Fragment>
      {!loading ? (
        <a
          className="text-danger d-inline-block remove-item-btn"
          href="#javascript"
          onClick={toggleDeleteModal}
        >
          <i className="ri-delete-bin-5-fill fs-16"></i>
        </a>
      ) : (
        <div className="spinner-border spinner-action text-success">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}

      <Modal
        isOpen={deletemodal}
        role="dialog"
        autoFocus={true}
        centered
        id="removeItemModal"
        toggle={toggleDeleteModal}
      >
        <ModalHeader
          toggle={() => {
            setDeleteModal(!deletemodal);
          }}
        ></ModalHeader>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#0ab39c,secondary:#405189"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you sure you want to remove this Advertiser ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => {
                setDeleteModal(!deletemodal);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger"
              onClick={() => handleDeleteBtn(offer._id)}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};
