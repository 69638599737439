import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { FORGOT_PASSWORD_MUTATION } from "_queries/Shared";
import { transformGQLErrors } from "_utils";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { ParticlesAuth } from "_components";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Label,
  Row,
} from "reactstrap";
import { APP_NAME } from "_constants";

const AWS_S3_BUCKET_URL = process.env.REACT_APP_AWS_S3_BUCKET_URL;

const schema = yup
  .object({
    email: yup.string().email().required("Email is required."),
  })
  .required();

export const ForgotPassword = () => {
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD_MUTATION, {
    errorPolicy: "all",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const response = await forgotPassword({
      variables: data,
    });

    if (response) {
      const isEmailSent =
        response && response.data && response.data.forgotPassword;

      if (isEmailSent) {
        reset();
        toast.success("Password reset email has been sent to your email.");
      }
    }

    //Show errors if any
    transformGQLErrors(response).map((e) => toast.error(e));
  };

  return (
    <Fragment>
      <div className="auth-page-wrapper pt-5">
        <ParticlesAuth>
          <div className="auth-page-content">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="text-center mt-sm-5 mb-4 text-white-50">
                    <div>
                      <Link to="/" className="d-inline-block auth-logo">
                        <img
                          src={`${AWS_S3_BUCKET_URL}/app-logos/logo-light.png`}
                          alt=""
                          height="20"
                        />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="mt-4">
                    <CardBody className="p-4">
                      <div className="text-center mt-2">
                        <h5 className="text-primary">Forgot Password?</h5>
                        <p className="text-muted">
                          Reset password with {APP_NAME}
                        </p>

                        <lord-icon
                          src="https://cdn.lordicon.com/rhvddzym.json"
                          trigger="loop"
                          colors="primary:#0ab39c"
                          className="avatar-xl"
                          style={{ width: "120px", height: "120px" }}
                        ></lord-icon>
                      </div>

                      <Alert
                        className="alert-borderless alert-warning text-center mb-2 mx-2"
                        role="alert"
                      >
                        Enter your email and instructions will be sent to you!
                      </Alert>
                      <div className="p-2">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                          <div className="mb-4">
                            <Label className="form-label">Email</Label>
                            <input
                              type="email"
                              placeholder="Enter your email"
                              {...register("email")}
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                            />
                            {errors.email?.message ? (
                              <FormFeedback type="invalid">
                                <div>{errors.email.message}</div>
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="text-center mt-4">
                            <button
                              className="btn btn-primary w-100"
                              type="submit"
                              disabled={loading}
                            >
                              Send Reset Link
                            </button>
                          </div>
                        </Form>
                      </div>
                    </CardBody>
                  </Card>

                  <div className="mt-4 text-center">
                    <p className="mb-0">
                      {`Wait, I remember my password... `}
                      <Link
                        to={`/login`}
                        className="fw-semibold text-primary text-decoration-underline"
                      >
                        Click here
                      </Link>
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </ParticlesAuth>
      </div>
    </Fragment>
  );
};
