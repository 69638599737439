export const TransactionStatus = (props) => {
  let highlightClassName = "";
  switch (props.status) {
    case "pending":
    case "manual":
      highlightClassName = "badge-soft-warning";
      break;
    case "approved":
    case "auto":
      highlightClassName = "badge-soft-success";
      break;
    case "declined":
      highlightClassName = "badge-soft-danger";
      break;
    case "awaiting_payment":
      highlightClassName = "badge-soft-secondary";
      break;
    case "payment_processing":
    case "unapplied":
      highlightClassName = "badge-soft-secondary";
      break;
    case "paid":
      highlightClassName = "badge-soft-success";
      break;
    default:
      highlightClassName = "badge-soft-info";
  }
  return (
    <span className={`badge text-uppercase ${highlightClassName}`}>
      {props.status}
    </span>
  );
};
