export const sortDatesAscending = (datesArray, column) => {
  datesArray.sort((a, b) => {
    const [day1, month1, year1] = a[column].split("-");
    const [day2, month2, year2] = b[column].split("-");

    const numYear1 = parseInt(year1);
    const numMonth1 = parseInt(month1);
    const numDay1 = parseInt(day1);

    const numYear2 = parseInt(year2);
    const numMonth2 = parseInt(month2);
    const numDay2 = parseInt(day2);

    if (numYear1 !== numYear2) {
      return numYear1 - numYear2;
    }

    if (numMonth1 !== numMonth2) {
      return numMonth1 - numMonth2;
    }

    return numDay1 - numDay2;
  });

  return datesArray;
};
