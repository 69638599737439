import { Loader } from "_components";
import { Fragment } from "react";
import { Card, Col, Row } from "reactstrap";
import { useQuery } from "@apollo/client";
import { DASHBOARD_BAR_GRAPH } from "_queries/Affiliate";
import ReactEcharts from "echarts-for-react";
import { getChartColorsArray, queryFilters, sortDatesAscending } from "_utils";
import moment from "moment";

export const BarGraph = ({ startDate, endDate, selectedCurrency }) => {
  const mockSales = [
    {
      currency: selectedCurrency,
      totalCommission: 0,
      totalSales: 0,
      date: moment().format("DD-MM-YYYY"),
    },
  ];

  const { data, loading } = useQuery(DASHBOARD_BAR_GRAPH, {
    variables: queryFilters({
      transactionDate: { start: startDate, end: endDate },
    }),
    fetchPolicy: "no-cache",
  });

  const dashboardBarGraph = data?.dashboardBarGraph;
  let chartData = dashboardBarGraph?.filter(
    (x) => x.currency === selectedCurrency
  );
  if (chartData && chartData.length === 0) {
    chartData = mockSales;
  }

  return (
    <Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            {loading ? (
              <Loader />
            ) : (
              <div className="card-body">
                <BarLabelChart
                  chartData={chartData}
                  dataColors='["--vz-primary", "--vz-secondary", "--vz-success", "--vz-warning", "--vz-danger"]'
                />
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const BarLabelChart = ({ dataColors, chartData = [] }) => {
  var chartBarLabelRotationColors = getChartColorsArray(dataColors);
  const sortedChartData = sortDatesAscending(chartData, "date");
  const commissions = sortedChartData.map((c) => +c.totalCommission.toFixed(2));
  const sales = sortedChartData.map((s) => s.totalSales);
  const dates = sortedChartData.map((s) => s.date);

  var option = {
    grid: {
      left: "0%",
      right: "0%",
      bottom: "0%",
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      data: ["Commission", "Orders"],
      textStyle: {
        //The style of the legend text
        color: "#858d98",
      },
    },
    color: chartBarLabelRotationColors,
    toolbox: {
      show: false,
    },
    xAxis: [
      {
        type: "category",
        axisTick: {
          show: false,
        },
        data: dates,
        axisLine: {
          lineStyle: {
            color: "#858d98",
          },
        },
      },
    ],
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#858d98",
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(133, 141, 152, 0.1)",
        },
      },
    },
    textStyle: {
      fontFamily: "Poppins, sans-serif",
    },
    series: [
      {
        name: "Commission",
        type: "bar",
        barGap: 0,
        emphasis: {
          focus: "series",
        },
        data: commissions,
      },
      {
        name: "Orders",
        type: "bar",
        emphasis: {
          focus: "series",
        },
        data: sales,
      },
    ],
  };

  return (
    <Fragment>
      <ReactEcharts style={{ height: "350px" }} option={option} />
    </Fragment>
  );
};
