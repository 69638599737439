import { useQuery } from "@apollo/client";
import { Fragment } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import SimpleBar from "simplebar-react";
import { Loader } from "_components";
import { GENERATED_INVOICE_STATS } from "_queries/Admin";

const mockSales = [
  { currency: "usd", totalProfit: 0 },
  { currency: "eur", totalProfit: 0 },
  { currency: "aud", totalProfit: 0 },
  { currency: "gbp", totalProfit: 0 },
  { currency: "cad", totalProfit: 0 },
];

export const GeneratedInvoiceWidget = ({ title }) => {
  const { data, loading } = useQuery(GENERATED_INVOICE_STATS);
  let sales = data?.generatedInvoiceStatsAdmin;
  if (sales && sales.length === 0) {
    sales = mockSales;
  }

  return (
    <Fragment>
      <Card style={{ height: "300px" }}>
        <CardHeader className="d-flex align-items-center">
          <h6 className="card-title mb-0 flex-grow-1">{title}</h6>
        </CardHeader>
        <CardBody>
          <div className="table-responsive table-card">
            <SimpleBar style={{ maxHeight: "246px" }}>
              {loading ? (
                <Loader />
              ) : (
                <ul className="list-group list-group-flush">
                  {sales &&
                    sales.map((item, key) => (
                      <li
                        key={key}
                        className="list-group-item list-group-item-action"
                      >
                        <div className="d-flex align-items-center">
                          <div className="ms-3 flex-grow-1">
                            <h6 className="fs-14 mb-1">
                              {item.currency.toUpperCase()}
                            </h6>
                          </div>
                          <div>
                            <h6>{item.invoiceAmount.toFixed(2)}</h6>
                          </div>
                        </div>
                      </li>
                    ))}
                  {sales && sales.length === 0 && (
                    <li>
                      <div className="mt-2" style={{ textAlign: "center" }}>
                        No data
                      </div>
                    </li>
                  )}
                </ul>
              )}
            </SimpleBar>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};
