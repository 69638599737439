import { gql } from "@apollo/client";

export const GET_ALL_OFFERS = gql`
  query (
    $filters: FiltersExpression!
    $pagination: PaginationInput!
    $orderBy: OrderByInput!
  ) {
    allOffersAdmin(
      filters: $filters
      pagination: $pagination
      orderBy: $orderBy
    ) {
      count
      offers {
        _id
        offerName
        referenceId
        commission
        country
        website
        approval
        logo
        platform
        deletedAt
        category {
          categoryName
        }
      }
    }
  }
`;

export const COUNT_ALL_OFFERS = gql`
  query ($filters: FiltersExpression!) {
    countAllOffersAdmin(filters: $filters) {
      totalActiveOffers
      totalDeletedOffers
    }
  }
`;

export const UPDATE_USER_OFFERS = gql`
  mutation updateUserOffersStatus(
    $id: String!
    $platform: String!
    $performance: String!
    $status: String!
  ) {
    updateUserOffersStatus(
      UpdateUserOffersStatusInput: {
        id: $id
        platform: $platform
        performance: $performance
        status: $status
      }
    )
  }
`;

export const DELETE_OFFER = gql`
  mutation removeOffer($id: String!) {
    removeOffer(id: $id) {
      _id
    }
  }
`;

export const RESTORE_OFFER = gql`
  mutation restoreOffer($id: String!) {
    restoreOffer(id: $id) {
      _id
    }
  }
`;

export const APPLY_ALL_OFFERS = gql`
  mutation applyAllOffers(
    $userId: String!
    $platform: String!
    $performance: String!
  ) {
    applyAllOffers(
      ApplyAllOffersInput: {
        userId: $userId
        platform: $platform
        performance: $performance
      }
    )
  }
`;

export const GET_OFFER = gql`
  query ($id: String!) {
    offer(id: $id) {
      _id
      offerName
      referenceId
      commission
      country
      website
      approval
      logo
      platform
      currency
      platformOfferId
      platformTrackingUrl
      performance
      featured
      category {
        _id
        categoryName
        referenceId
      }
    }
  }
`;

export const UPDATE_OFFER = gql`
  mutation UpdateOffer(
    $offerName: String!
    $commission: Float!
    $website: String!
    $country: String!
    $currency: String!
    $category: String!
    $platform: String!
    $platformOfferId: String!
    $platformTrackingUrl: String!
    $approval: String!
    $performance: String!
    $featured: Boolean!
    $termsNConditions: String
    $logo: String!
    $id: String!
  ) {
    updateOffer(
      updateOfferInput: {
        offerName: $offerName
        commission: $commission
        website: $website
        country: $country
        currency: $currency
        category: $category
        platform: $platform
        platformOfferId: $platformOfferId
        platformTrackingUrl: $platformTrackingUrl
        approval: $approval
        performance: $performance
        featured: $featured
        termsNConditions: $termsNConditions
        logo: $logo
        id: $id
      }
    ) {
      _id
    }
  }
`;

export const GET_ALL_OFFER_APPROVAL_TYPES = gql`
  query {
    offerApprovalTypes
  }
`;

export const GET_ALL_OFFER_PERFORMANCE_TYPES = gql`
  query {
    offerPerformanceTypes
  }
`;

export const SEARCH_OFFERS = gql`
  query ($filters: FiltersExpression!) {
    searchOffersAdmin(filters: $filters) {
      _id
      offerName
      referenceId
    }
  }
`;
