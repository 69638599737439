import { Fragment, useState } from "react";
import { Col, Row } from "reactstrap";
import { offersFilter } from "_utils";
import { DEFAULT_PER_PAGE } from "_constants";
import { Filters } from "./Filters";
import { Listing } from "./Listing";
import { useQuery } from "@apollo/client";
import { COUNT_ALL_OFFERS, GET_ALL_OFFERS } from "_queries/Admin";
import { StatWidgets } from "_components";

const blockWidgets = [
  {
    id: 1,
    label: "Active Advertisers",
    icon: "ri-layout-2-fill",
    decimals: "",
    prefix: "",
    key: "totalActiveOffers",
  },
  {
    id: 2,
    label: "Deleted Advertisers",
    icon: "ri-layout-4-fill",
    decimals: "",
    prefix: "",
    key: "totalDeletedOffers",
  },
  {
    id: 3,
    label: "Total Advertisers",
    icon: "ri-layout-row-fill",
    decimals: "",
    prefix: "",
    key: "totalOffers",
  },
];

export const AllOffers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [filters, setFilters] = useState(null);

  const { data, loading, refetch } = useQuery(GET_ALL_OFFERS, {
    variables: offersFilter(currentPage, perPage, filters),
  });

  const { data: dataCountAllOffers, loading: loadingCountAllOffers } = useQuery(
    COUNT_ALL_OFFERS,
    {
      variables: offersFilter(currentPage, perPage, filters),
    }
  );

  const paginatedData = data?.allOffersAdmin?.offers;
  const numberOfTotalPages = data?.allOffersAdmin.count;
  const offersCount = dataCountAllOffers?.countAllOffersAdmin;
  const countAllOffers = {
    ...offersCount,
    totalOffers:
      offersCount?.totalActiveOffers + offersCount?.totalDeletedOffers,
  };

  const handleFilterChange = (key, value) => {
    setFilters(() => {
      return {
        ...filters,
        [key]: value,
      };
    });
  };

  const statsProps = {
    data: countAllOffers,
    loading: loadingCountAllOffers,
    blocks: blockWidgets,
  };

  const filtersProps = {
    setCurrentPage,
    handleFilterChange,
  };

  const listingProps = {
    numberOfTotalPages,
    paginatedData,
    setCurrentPage,
    setPerPage,
    currentPage,
    perPage,
    loading,
    refetch,
  };

  return (
    <Fragment>
      <Row id="stats">
        <StatWidgets {...statsProps} />
      </Row>
      <Row id="filter">
        <Col lg={12}>
          <Filters {...filtersProps} />
        </Col>
      </Row>
      <Row id="listing">
        <Col lg={12}>
          <Listing {...listingProps} />
        </Col>
      </Row>
    </Fragment>
  );
};
