import { SalesBlocks } from "./SalesBlocks";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Fragment, useEffect } from "react";
import { useState } from "react";
import { PieChartAllCurrencySales } from "./PieChartAllCurrencySales";
import { useQuery } from "@apollo/client";
import { DASHBOARD_SALES_STATS, TOP_OFFERS } from "_queries/Affiliate";
import { BarGraph } from "./BarGraph";
import { DashboardFilters } from "./DashboardFilters";
import { endDateOfCurrentMonth, transactionsFilter } from "_utils";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import { Pagination, Navigation, Autoplay } from "swiper";
import { SalesGrid } from "./SalesGrid";
import { Loader } from "_components";
import { DEFAULT_START_DATE } from "_constants";
import { TopOffers } from "./TopOffers";

const AWS_S3_BUCKET_URL = process.env.REACT_APP_AWS_S3_BUCKET_URL;

export const Dashboard = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState("usd");

  const {
    data: dataAllCurrencyData,
    loading: loadingAllCurrencyData,
    refetch,
  } = useQuery(DASHBOARD_SALES_STATS, {
    variables: transactionsFilter(null, null, {
      transactionDate: { start: startDate, end: endDate },
    }),
    fetchPolicy: "no-cache",
  });

  const {
    data: dataTopOffers,
    loading: loadingTopOffers,
    refetch: refetchTopOffers,
  } = useQuery(TOP_OFFERS, {
    variables: { filters: { fields: [] } },
  });

  const allCurrencyData = dataAllCurrencyData?.dashboardSalesStats || [];
  const topOffers = dataTopOffers?.topOffers || [];
  const selectedCurrencyData = allCurrencyData.find(
    (x) => x.currency === selectedCurrency
  );
  if (allCurrencyData.length > 0 && !selectedCurrencyData) {
    const { currency } = allCurrencyData.find(Boolean);
    setSelectedCurrency(currency);
  }

  useEffect(() => {
    refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  return (
    <Fragment>
      <Row>
        <Col xs={12}>
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            pagination={{ clickable: true }}
            navigation={true}
            loop={true}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            className="mySwiper swiper navigation-swiper rounded"
            style={{ height: "400px" }}
          >
            <div className="swiper-wrapper">
              {[...Array(7)].map((x, i) => (
                <SwiperSlide key={i}>
                  <img
                    src={`${AWS_S3_BUCKET_URL}/dashbboard-banners/banner${
                      i + 1
                    }.jpg`}
                    alt={`Banner ${i + 1}`}
                    className="img-fluid"
                  />
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">&nbsp;</h4>

            <DashboardFilters
              allCurrencyData={allCurrencyData}
              selectedCurrency={selectedCurrency}
              onStartDateChange={(startDate) => {
                setStartDate(startDate);
              }}
              onEndDateChange={(endDate) => {
                setEndDate(endDate);
              }}
              onCurrencyChange={(currency) => {
                setSelectedCurrency(currency);
              }}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col className="col-xxl-12">
          <SalesBlocks
            startDate={startDate}
            endDate={endDate}
            loadingAllCurrencyData={loadingAllCurrencyData}
            selectedCurrencyData={selectedCurrencyData}
            selectedCurrency={selectedCurrency}
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-xxl-3">
          <PieChartAllCurrencySales
            loadingAllCurrencyData={loadingAllCurrencyData}
            allCurrencyData={allCurrencyData}
          />
        </Col>
        <Col className="col-xxl-9 order-xxl-0 order-first">
          <BarGraph
            startDate={startDate}
            endDate={endDate}
            selectedCurrency={selectedCurrency}
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-xxl-3">
          <SalesGrid
            startDate={startDate}
            endDate={endDate}
            status="pending"
            title="Pending Commission"
            slug="pending-commission"
          />
          <SalesGrid
            startDate={DEFAULT_START_DATE}
            endDate={endDateOfCurrentMonth()}
            status="awaiting_payment"
            title="Withdraw Now"
            slug="withdraw-now"
          />
          <SalesGrid
            startDate={DEFAULT_START_DATE}
            endDate={endDateOfCurrentMonth()}
            status="payment_processing"
            title="Payment Processing"
            slug="payment-processing"
          />
        </Col>
        <Col className="col-xxl-9 order-xxl-0 order-first">
          <Card>
            <CardHeader className="d-flex align-items-center">
              <h6 className="card-title mb-0 flex-grow-1">Top Advertisers</h6>
            </CardHeader>
            <CardBody>
              <div className="table-responsive table-card table-custom mb-4">
                {loadingTopOffers ? (
                  <Loader />
                ) : (
                  <TopOffers
                    data={topOffers}
                    refetch={refetchTopOffers}
                    loadingOffers={loadingTopOffers}
                  ></TopOffers>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
