import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  UsersDropdown,
  OfferPerformanceDropdown,
  PlatformDropdown,
} from "_components";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { APPLY_ALL_OFFERS } from "_queries/Admin";
import { Card, CardBody, CardHeader, Col, Label, Row, Form } from "reactstrap";

export const OffersApply = () => {
  const [userId, setUserId] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [performance, setPerformance] = useState(null);
  const [applyAllOffers, { loading }] = useMutation(APPLY_ALL_OFFERS, {
    errorPolicy: "all",
  });

  const { handleSubmit } = useForm();

  const onSubmit = async () => {
    const response = await applyAllOffers({
      variables: { userId, performance, platform },
    });

    if (response) {
      const applyAllOffers =
        response && response.data && response.data.applyAllOffers;

      toast.success(`${applyAllOffers} advertiser(s) have been applied.`);
    }
  };

  return (
    <Row>
      <Col lg={12}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader className="align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Apply Advertisers</h4>
            </CardHeader>
            <CardBody className="card-body">
              <div className="live-preview">
                <Row className="gy-4">
                  <Col xxl={3} md={6}>
                    <div>
                      <Label className="form-label">Users</Label>
                      <UsersDropdown
                        onUserChange={(user) => {
                          setUserId(user);
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={3} md={6}>
                    <div>
                      <Label className="form-label">Platforms</Label>
                      <PlatformDropdown
                        onPlatformChange={(platform) => {
                          setPlatform(platform);
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={3} md={6}>
                    <div>
                      <Label className="form-label">Performance</Label>
                      <OfferPerformanceDropdown
                        onOfferPerformanceChange={(performance) => {
                          setPerformance(performance);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
          <div className="text-end mb-3">
            <button
              type="submit"
              className="btn btn-success w-sm"
              disabled={loading}
            >
              Apply All
            </button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};
