import { Fragment } from "react";
import { timestampToDate } from "_utils";
import { InvoiceStatus, NoRecordFoundTable } from "_components";
import { invoiceNumber } from "_utils/invoice-number";

export const InvoiceListingTable = (props) => {
  return (
    <Fragment>
      <table className="table align-middle table-nowrap mb-0">
        <thead className="table-light text-muted">
          <tr>
            {props.columns.map((column, index) => (
              <th className={column.class} key={index}>
                {column.label}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="list form-check-all">
          {props.data &&
            props.data.map((invoice) => (
              <tr key={invoice._id}>
                <td>{invoiceNumber(invoice.invoiceNo)}</td>
                <td>{invoice.fromCurrency.toUpperCase()}</td>
                <td>{invoice.fromAmount}</td>
                <td>{invoice.paidCurrency.toUpperCase()}</td>
                <td>{invoice.paidAmount}</td>
                <td>{timestampToDate(invoice.requestedDate)}</td>
                <td>{timestampToDate(invoice.paidDate)}</td>
                <td>
                  <InvoiceStatus status={invoice.status} />
                </td>
                <props.AdditionalColumnsValuesComponents
                  invoice={invoice}
                  refetch={props.refetch}
                />
              </tr>
            ))}
        </tbody>
      </table>
      {props.data && props.data.length === 0 && <NoRecordFoundTable />}
    </Fragment>
  );
};
