import Select from "react-select";

export const PeriodDropdown = (props) => {
  const { onPeriodChange } = props;
  const periods = [
    { label: "By Day", value: "by_day" },
    { label: "By Month", value: "by_month" },
    { label: "By Year", value: "by_year" },
    { label: "By Brand", value: "by_brand" },
  ];

  const handleChange = (inputValue) => {
    const val = inputValue ? inputValue.value : "by_day";
    onPeriodChange(val);
  };

  return (
    <Select
      placeholder={`Select Period`}
      isClearable={false}
      defaultOptions={true}
      defaultValue={periods[0]}
      options={periods}
      onChange={handleChange}
    />
  );
};
