import Select from "react-select";
import { transformDataIntoValueLabel } from "_utils";

export const OfferStatusDropdown = (props) => {
  const { onOfferStatusChange } = props;
  const statuses = [
    { title: "Approve", value: "approved" },
    { title: "Decline", value: "declined" },
  ];

  const handleChange = (inputValue) => {
    const val = inputValue ? inputValue.value : "all";
    onOfferStatusChange(val);
  };

  return (
    <Select
      placeholder={`Select Status`}
      isClearable={true}
      defaultOptions={false}
      options={transformDataIntoValueLabel(statuses, "title", "value")}
      onChange={handleChange}
    />
  );
};
