import { gql } from "@apollo/client";

export const GET_ALL_OFFERS = gql`
  query (
    $filters: FiltersExpression!
    $pagination: PaginationInput!
    $orderBy: OrderByInput!
  ) {
    allOffers(filters: $filters, pagination: $pagination, orderBy: $orderBy) {
      count
      offers {
        _id
        offerName
        referenceId
        commission
        country
        website
        logo
        category {
          categoryName
        }
      }
    }
  }
`;

export const GET_MY_OFFERS = gql`
  query (
    $filters: FiltersExpression!
    $pagination: PaginationInput!
    $orderBy: OrderByInput!
  ) {
    appliedOffers(
      filters: $filters
      pagination: $pagination
      orderBy: $orderBy
    ) {
      count
      offers {
        _id
        offerName
        referenceId
        commission
        country
        website
        logo
        category {
          categoryName
        }
      }
    }
  }
`;

export const APPLY_OFFER = gql`
  mutation ApplyOffer($offerId: String!) {
    applyOffer(createApplyOfferInput: { offerId: $offerId }) {
      _id
    }
  }
`;

export const APPLY_OFFERS = gql`
  mutation ApplyOffers($offerIds: [String!]!) {
    applyOffers(createApplyOffersInput: { offerIds: $offerIds })
  }
`;

export const TOP_OFFERS = gql`
  query ($filters: FiltersExpression!) {
    topOffers(filters: $filters) {
      _id
      offerName
      referenceId
      commission
      country
      website
      logo
      userAppliedStatus
      category {
        categoryName
      }
    }
  }
`;

export const SEARCH_OFFERS = gql`
  query ($filters: FiltersExpression!) {
    searchOffers(filters: $filters) {
      _id
      offerName
      referenceId
    }
  }
`;
