import { useMutation } from "@apollo/client";
import { RESTORE_OFFER } from "_queries/Admin";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

export const RestoreOfferButton = ({ offer, refetch }) => {
  const [loading, setLoading] = useState(false);
  const [restoremodal, setRestoreModal] = useState(false);
  const [restoreOffer] = useMutation(RESTORE_OFFER, {
    errorPolicy: "all",
  });

  const handleRestoreBtn = async (offerId) => {
    setLoading(true);
    const response = await restoreOffer({
      variables: { id: offerId },
    });
    if (response) {
      toast.success(`Advertiser has been restored successfully.`);
      refetch();
      setLoading(false);
      setRestoreModal(!restoremodal);
    }
  };

  const toggleRestoreModal = () => {
    setRestoreModal(!restoremodal);
  };

  return (
    <Fragment>
      {!loading ? (
        <a
          className="text-warning d-inline-block remove-item-btn"
          href="#javascript"
          onClick={toggleRestoreModal}
        >
          <i className="ri-folder-transfer-fill fs-16"></i>
        </a>
      ) : (
        <div className="spinner-border spinner-action text-success">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}

      <Modal
        isOpen={restoremodal}
        role="dialog"
        autoFocus={true}
        centered
        id="removeItemModal"
        toggle={toggleRestoreModal}
      >
        <ModalHeader
          toggle={() => {
            setRestoreModal(!restoremodal);
          }}
        ></ModalHeader>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/nocovwne.json"
              trigger="loop"
              colors="primary:#0ab39c,secondary:#405189"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you sure you want to restore this Advertiser ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => {
                setRestoreModal(!restoremodal);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-warning"
              onClick={() => handleRestoreBtn(offer._id)}
            >
              Yes, Restore It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};
