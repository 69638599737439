export const InvoiceStatus = (props) => {
  let highlightClassName = "";
  switch (props.status) {
    case "pending":
    case "manual":
      highlightClassName = "badge-soft-warning";
      break;
    case "paid":
      highlightClassName = "badge-soft-success";
      break;
    default:
      highlightClassName = "badge-soft-info";
  }
  return (
    <span className={`badge text-uppercase ${highlightClassName}`}>
      {props.status}
    </span>
  );
};
