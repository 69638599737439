import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  OfferStatusDropdown,
  UsersDropdown,
  OfferPerformanceDropdown,
  PlatformDropdown,
} from "_components";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_OFFERS } from "_queries/Admin";
import { Card, CardBody, CardHeader, Col, Label, Row, Form } from "reactstrap";

export const OffersApprovedAndDeclined = () => {
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [performance, setPerformance] = useState(null);
  const [updateUserOffers, { loading }] = useMutation(UPDATE_USER_OFFERS, {
    errorPolicy: "all",
  });

  const { handleSubmit } = useForm();

  const onSubmit = async () => {
    const response = await updateUserOffers({
      variables: { id: user, status, performance, platform },
    });

    if (response) {
      const updateUserOffersStatus =
        response && response.data && response.data.updateUserOffersStatus;

      if (updateUserOffersStatus) {
        toast.success(
          `${updateUserOffersStatus} advertiser(s) have been ${status.toUpperCase()}`
        );
      }
    }
  };

  return (
    <Row>
      <Col lg={12}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader className="align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">
                Approved/Declined Advertisers
              </h4>
            </CardHeader>
            <CardBody className="card-body">
              <div className="live-preview">
                <Row className="gy-4">
                  <Col xxl={3} md={6}>
                    <div>
                      <Label className="form-label">Users</Label>
                      <UsersDropdown
                        onUserChange={(user) => {
                          setUser(user);
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={3} md={6}>
                    <div>
                      <Label className="form-label">Platforms</Label>
                      <PlatformDropdown
                        onPlatformChange={(platform) => {
                          setPlatform(platform);
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={3} md={6}>
                    <div>
                      <Label className="form-label">Performance</Label>
                      <OfferPerformanceDropdown
                        onOfferPerformanceChange={(performance) => {
                          setPerformance(performance);
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={3} md={6}>
                    <div>
                      <Label className="form-label">Advertiser Status</Label>
                      <OfferStatusDropdown
                        onOfferStatusChange={(status) => {
                          setStatus(status);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
          <div className="text-end mb-3">
            <button
              type="submit"
              className="btn btn-success w-sm"
              disabled={loading}
            >
              Submit
            </button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};
