import { Fragment } from "react";
import { firstLetterCapital } from "_utils";
import {
  NoRecordFoundTable,
  TransactionStatus,
  OfferInfoInTable,
  ApplyOfferButton,
} from "_components";

const columns = [
  { label: "Advertiser", class: "min-w-250px" },
  { label: "Category", class: "min-w-250px" },
  { label: "Commission", class: "min-w-100px" },
  { label: "Country", class: "min-w-200px" },
  { label: "Status", class: "min-w-100px" },
  { label: "Action", class: "text-center min-w-200px" },
];

export const ListingTable = ({
  data,
  refetch,
  selectedOffers,
  onSelectOffers,
  loadingOffers,
  loadingApplyOffers,
}) => {
  // Checked Single
  const handleCheckboxChange = (event) => {
    const checkedValue = event.target.value;
    const isChecked = event.target.checked;

    const newSelectedOffers = isChecked
      ? [...selectedOffers, checkedValue]
      : selectedOffers.filter((value) => value !== checkedValue);
    onSelectOffers(newSelectedOffers);
  };

  // Checked All
  const handleCheckboxAllChange = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".taskCheckBox");
    let newSelectedOffers = [];

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
        newSelectedOffers.push(ele.value);
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
      newSelectedOffers = [];
    }

    onSelectOffers(newSelectedOffers);
  };

  return (
    <Fragment>
      <table className="table align-middle table-nowrap mb-0">
        <thead className="table-light text-muted">
          <tr>
            <th>
              <input
                type="checkbox"
                id="checkBoxAll"
                className="form-check-input"
                onChange={handleCheckboxAllChange}
              />
            </th>
            {columns.map((column, index) => (
              <th key={index}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody className="list form-check-all">
          {data &&
            data.map((offer) => (
              <tr key={offer._id}>
                <td>
                  <input
                    type="checkbox"
                    className="taskCheckBox form-check-input"
                    value={offer._id}
                    onChange={handleCheckboxChange}
                  />
                </td>
                <td>
                  <OfferInfoInTable offer={offer} />
                </td>
                <td>{offer.category?.categoryName}</td>
                <td>
                  <span>
                    <span className="badge bg-light text-body fs-12 fw-medium">
                      <i className="mdi mdi-star text-warning me-1"></i>
                      {offer.commission}%
                    </span>
                  </span>
                </td>
                <td>{firstLetterCapital(offer.country)}</td>
                <td>
                  <TransactionStatus status={`unapplied`} />
                </td>
                <td className="text-center">
                  <ApplyOfferButton
                    offer={offer}
                    refetch={refetch}
                    loadingOffers={loadingOffers}
                    loadingApplyOffers={loadingApplyOffers}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {data && data.length === 0 && <NoRecordFoundTable />}
    </Fragment>
  );
};
