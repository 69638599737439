import Select from "react-select";
import { transformDataIntoValueLabel } from "_utils";
import { useQuery } from "@apollo/client";
import { GET_PROFILE } from "_queries/Shared";
import { Fragment } from "react";
import { Loader } from "_components";

export const UserBankAccountsDropdown = (props) => {
  const { onBankAccountChange } = props;
  const { data, loading } = useQuery(GET_PROFILE, {
    fetchPolicy: "no-cache",
  });
  const bankAccounts = data?.me?.bankAccountDetails;
  const bankAccountsMod =
    bankAccounts &&
    bankAccounts.map((acc, index) => {
      const accountTitle =
        acc.paymentMethod === "bank"
          ? `${acc.bankName}-${acc.accountName}-${
              acc.accountNumber
            } (${acc.paymentMethod.toUpperCase()})`
          : `${acc.email} (${acc.paymentMethod.toUpperCase()})`;
      return {
        ...acc,
        value: accountTitle,
      };
    });

  const handleChangeBankAccount = (inputValue) => {
    const val = inputValue ? inputValue.value : null;
    onBankAccountChange(val);
  };
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Select
          placeholder={`Select Bank Account`}
          isClearable={false}
          defaultOptions={false}
          menuPortalTarget={document.body}
          options={transformDataIntoValueLabel(bankAccountsMod, "value", "_id")}
          onChange={handleChangeBankAccount}
        />
      )}
    </Fragment>
  );
};
