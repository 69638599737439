import { gql } from "@apollo/client";

export const GET_TRANSACTIONS = gql`
  query (
    $filters: FiltersExpression!
    $pagination: PaginationInput!
    $orderBy: OrderByInput!
  ) {
    transactionsAdmin(
      filters: $filters
      pagination: $pagination
      orderBy: $orderBy
    ) {
      count
      transactions {
        _id
        commission
        saleAmount
        status
        transactionDate
        referenceId
        currency
        payable
        profit
        commissionPercentage
        platform
        reference {
          ref1
          ref2
          ref3
          ref4
          ref5
        }
        offer {
          offerName
          referenceId
          logo
        }
        user {
          firstName
          lastName
          website
          referenceId
        }
        invoice {
          invoiceNo
        }
      }
    }
  }
`;

export const TRANSACTIONS_STATS = gql`
  query ($filters: FiltersExpression!) {
    transactionsAdminStats(filters: $filters) {
      currency
      totalSales
      totalSaleAmount
      totalCommission
      totalPayable
      totalProfit
    }
  }
`;

export const DASHBOARD_SALES_STATS = gql`
  query ($filters: FiltersExpression!) {
    dashboardSalesStatsAdmin(filters: $filters) {
      currency
      totalSales
      totalSaleAmount
      totalCost
      totalProfit
    }
  }
`;

export const DASHBOARD_BAR_GRAPH = gql`
  query ($filters: FiltersExpression!) {
    dashboardBarGraphAdmin(filters: $filters) {
      currency
      totalSales
      totalSaleAmount
      totalCommission
      date
    }
  }
`;

export const USER_SALES_REPORT = gql`
  query ($filters: FiltersExpression!) {
    userSalesReportAdmin(filters: $filters) {
      user {
        _id
        firstName
        lastName
        website
        referenceId
      }
      data {
        currency
        totalSales
        totalSaleAmount
        totalCommission
        totalPayable
        totalProfit
      }
    }
  }
`;

export const UPDATE_TRANSACTIONS_STATUS = gql`
  mutation UpdateTransactionsStatus($transactionIds: [String!]!) {
    updateTransactionsStatus(
      updateTransactionStatusInput: { transactionIds: $transactionIds }
    )
  }
`;
