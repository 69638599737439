import { Loader } from "_components";
import { Fragment, useState } from "react";
import { Card, CardBody, CardHeader, Collapse, Table } from "reactstrap";
export const Listing = ({
  performanceReportData,
  performanceReportLoading,
  filters,
}) => {
  const [collapsedRows, setCollapsedRows] = useState({ 0: true, 1: true });

  const toggleRow = (id) => {
    setCollapsedRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <Fragment>
      <Card>
        <CardHeader className="card-header border-0">
          <div className="d-flex align-items-center">
            <h5 className="card-title mb-0 flex-grow-1">Performance Report</h5>
            <div className="flex-shrink-0"></div>
          </div>
        </CardHeader>
        <CardBody className="border border-dashed border-end-0 border-start-0">
          <div className="table-responsive table-card mb-4 table-transaction">
            {performanceReportLoading ? (
              <Loader />
            ) : (
              <Fragment>
                <table className="table align-middle table-nowrap mb-0 performance-report-table">
                  <tbody>
                    {performanceReportData &&
                      performanceReportData.performanceReport.map(
                        (parentRow, index) => (
                          <Fragment key={index}>
                            <tr
                              className="date-row"
                              onClick={() => toggleRow(index)}
                              key={index}
                            >
                              {filters.period === "by_brand" ? (
                                <td>{parentRow.offerName}</td>
                              ) : (
                                <td>{parentRow.date}</td>
                              )}
                            </tr>
                            <tr>
                              <td colSpan="2">
                                <Collapse isOpen={collapsedRows[index]}>
                                  <Table>
                                    <thead className="table-light text-muted">
                                      <tr>
                                        <th>Currency</th>
                                        <th>Orders</th>
                                        <th>Clicks</th>
                                        <th>Sale Amount</th>
                                        <th>Commission</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {parentRow.reportsData.map(
                                        (childRow, index) => (
                                          <tr key={index}>
                                            <td>
                                              {childRow.currency.toUpperCase()}
                                            </td>
                                            <td>{childRow.totalSales}</td>
                                            {index === 0 && (
                                              <td
                                                className="clicks-column"
                                                rowSpan={
                                                  parentRow.reportsData.length
                                                }
                                              >
                                                {parentRow.totalVisits}
                                              </td>
                                            )}
                                            <td>
                                              {childRow.totalSaleAmount.toFixed(
                                                2
                                              )}
                                            </td>
                                            <td>
                                              {childRow.totalPayable.toFixed(2)}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </Collapse>
                              </td>
                            </tr>
                          </Fragment>
                        )
                      )}
                  </tbody>
                </table>
              </Fragment>
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};
