import { Fragment } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment/moment";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

const mockCurrencies = [
  { currency: "usd" },
  { currency: "eur" },
  { currency: "aud" },
  { currency: "gbp" },
  { currency: "cad" },
];

export const DashboardFilters = ({
  allCurrencyData,
  selectedCurrency,
  onStartDateChange,
  onEndDateChange,
  onCurrencyChange,
}) => {
  if (allCurrencyData && allCurrencyData.length === 0) {
    allCurrencyData = mockCurrencies;
  }

  const handleEventFilterDate = (event, picker) => {
    const { startDate, endDate } = picker;
    const start = moment(startDate).format("Y-MM-DD");
    const end = moment(endDate).format("Y-MM-DD");
    onStartDateChange(start);
    onEndDateChange(end);
  };

  const onChangeCurrency = (cType) => {
    onCurrencyChange(cType);
  };

  return (
    <Fragment>
      <div className="page-title-right d-sm-flex">
        <div className="mr-1">
          <DateRangePicker
            initialSettings={{
              startDate: moment().startOf("month"),
              endDate: moment().endOf("month"),
            }}
            onApply={handleEventFilterDate}
            className="w-50"
          >
            <input type="text" className="form-control" />
          </DateRangePicker>
        </div>
        <div className="ms-2">
          <UncontrolledDropdown direction="start">
            <DropdownToggle tag="button" className="btn btn-primary">
              <span className="text-uppercase">
                {selectedCurrency}
                <i className="mdi mdi-chevron-down align-middle ms-1"></i>
              </span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu dropdown-menu-end">
              {(allCurrencyData || []).map((stats, index) => (
                <DropdownItem
                  onClick={() => {
                    onChangeCurrency(stats.currency);
                  }}
                  className={
                    selectedCurrency === stats.currency ? "active" : ""
                  }
                  key={index}
                >
                  {stats.currency.toUpperCase()}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </Fragment>
  );
};
