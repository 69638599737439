import { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import { CopyRightContent } from "_components";

export const Footer = () => {
  return (
    <Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col sm={12}>
              <CopyRightContent />
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
};
