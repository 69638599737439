import { useMutation } from "@apollo/client";
import { InvoiceStatus } from "_constants";
import { DELETE_INVOICE } from "_queries/Admin";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

export const DeletedInvoiceButton = ({ invoice, refetch }) => {
  const [deleteBtnDisabled, setDeleteBtnDisabled] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);
  const [deleteInvoice] = useMutation(DELETE_INVOICE, {
    errorPolicy: "all",
  });

  const handleDeleteBtn = async () => {
    setDeleteBtnDisabled(true);

    const response = await deleteInvoice({
      variables: { invoiceId: invoice._id },
    });

    if (response) {
      toast.success(`Invoice has been deleted.`);
      refetch();
    }
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deletemodal);
  };

  return (
    <Fragment>
      <button
        className="btn btn-sm btn-danger m-2"
        onClick={toggleDeleteModal}
        disabled={deleteBtnDisabled || invoice.status === InvoiceStatus.PAID}
      >
        Delete
      </button>
      <Modal
        isOpen={deletemodal}
        role="dialog"
        autoFocus={true}
        centered
        id="removeItemModal"
        toggle={toggleDeleteModal}
      >
        <ModalHeader
          toggle={() => {
            setDeleteModal(!deletemodal);
          }}
        ></ModalHeader>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#0ab39c,secondary:#405189"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you sure you want to remove this Invoice ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => {
                setDeleteModal(!deletemodal);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger"
              onClick={handleDeleteBtn}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};
