import { useQuery } from "@apollo/client";
import { Fragment } from "react";
import { Card, CardBody, CardHeader, Row, Table } from "reactstrap";

import { Loader, NoRecordFoundTable } from "_components";
import { APP_NAME } from "_constants";
import { GET_PROFILE } from "_queries/Shared";
import { fullName } from "_utils";
import { AddBankAccount } from "./AddBankAccount";

export const BankDetails = () => {
  const { data, loading, refetch } = useQuery(GET_PROFILE, {
    variables: {},
    fetchPolicy: "no-cache",
  });
  const profile = data?.me;

  return (
    <Fragment>
      <Row>
        <div className="col-xxl-3">
          <Card>
            <CardBody className="p-4">
              {loading ? (
                <Loader />
              ) : (
                <div>
                  <div className="mt-4 text-center">
                    <h5 className="mb-1">
                      {fullName(profile.firstName, profile.lastName)}
                    </h5>
                  </div>
                  <div className="table-responsive">
                    <Table className="table mb-0 table-borderless">
                      <tbody>
                        <tr>
                          <th>
                            <span className="fw-medium">Email</span>
                          </th>
                          <td>{profile.email}</td>
                        </tr>
                        <tr>
                          <th>
                            <span className="fw-medium">Phone</span>
                          </th>
                          <td>{profile.phone}</td>
                        </tr>
                        <tr>
                          <th>
                            <span className="fw-medium">Country</span>
                          </th>
                          <td>{profile.country}</td>
                        </tr>
                        <tr>
                          <th>
                            <span className="fw-medium">City</span>
                          </th>
                          <td>{profile.city}</td>
                        </tr>
                        <tr>
                          <th>
                            <span className="fw-medium">State</span>
                          </th>
                          <td>{profile.state}</td>
                        </tr>

                        <tr>
                          <th>
                            <span className="fw-medium">Website</span>
                          </th>
                          <td>{profile.website}</td>
                        </tr>
                        <tr>
                          <th>
                            <span className="fw-medium">{APP_NAME} ID</span>
                          </th>
                          <td>{profile.referenceId}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </div>
        <div className="col-xxl-9">
          <AddBankAccount refetchAccountDetails={refetch} />
          <Card>
            <CardHeader className="card-header border">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">Account Details</h5>
                <div className="flex-shrink-0"></div>
              </div>
            </CardHeader>
            <CardBody>
              <div className="table-responsive table-card mb-4">
                {loading ? (
                  <Loader />
                ) : (
                  <Fragment>
                    <table className="table align-middle table-nowrap mb-0">
                      <thead className="table-light text-muted">
                        <tr>
                          <th className="min-w-100px">Payment Method</th>
                          <th className="min-w-100px">Email</th>
                          <th className="min-w-100px">Bank Name</th>
                          <th className="min-w-100px">Bank Address</th>
                          <th className="min-w-100px">Account Type</th>
                          <th className="min-w-100px">Account Name</th>
                          <th className="min-w-100px">Account No</th>
                        </tr>
                      </thead>

                      <tbody className="list form-check-all">
                        {(profile.bankAccountDetails || []).map(
                          (accountDetail, idx) => (
                            <tr key={idx}>
                              <td className="text-capitalize">
                                {accountDetail.paymentMethod}
                              </td>
                              <td>{accountDetail.email}</td>
                              <td>{accountDetail.bankName}</td>
                              <td>{accountDetail.bankAddress}</td>
                              <td>{accountDetail.accountType}</td>
                              <td>{accountDetail.accountName}</td>
                              <td>{accountDetail.accountNumber}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                    {profile.bankAccountDetails &&
                      profile.bankAccountDetails.length === 0 && (
                        <NoRecordFoundTable />
                      )}
                  </Fragment>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      </Row>
    </Fragment>
  );
};
