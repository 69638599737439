import { useQuery } from "@apollo/client";
import Select from "react-select";
import { GET_ALL_PLATFORMS } from "_queries/Admin";
import { firstLetterCapital, transformDataIntoValueLabel } from "_utils";

export const PlatformDropdown = (props) => {
  const { onPlatformChange } = props;
  let platforms = [];
  const { data } = useQuery(GET_ALL_PLATFORMS);
  const platformsData = data?.allPlatforms.platforms.map((platform) => {
    return {
      _id: platform,
      title: `${firstLetterCapital(platform)}`,
    };
  });
  if (platformsData) {
    platforms = [{ _id: "all", title: "All" }].concat(platformsData);
  }

  const handleChange = (inputValue) => {
    const val = inputValue ? inputValue.value : "all";
    onPlatformChange(val);
  };
  return (
    <Select
      placeholder={`Select Platform`}
      isClearable={true}
      defaultOptions={false}
      options={transformDataIntoValueLabel(platforms, "title", "_id")}
      onChange={handleChange}
    />
  );
};
