export const YesNoStatus = (props) => {
  let highlightClassName = "";
  switch (props.status) {
    case "true":
    case "yes":
    case "YES":
      highlightClassName = "badge-soft-success";
      break;
    case "false":
    case "no":
    case "NO":
      highlightClassName = "badge-soft-danger";
      break;
    default:
      highlightClassName = "badge-soft-info";
  }
  return (
    <span className={`badge text-uppercase ${highlightClassName}`}>
      {props.status}
    </span>
  );
};
